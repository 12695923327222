/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import Layout  from './Layouts/Layout'
import ShareModal from './modal/ShareModal'
import Modal from './modal/Modal'
import ViewRow from './ViewRow'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { logout } from '../features/slices/authslice.';


const FavouriteHorses = () => {
    
    
    const dispatch = useDispatch()
    
    const [show, setShow] = useState(false)

    const [tabActive, setTabActive] = useState(1)
    const [showShare, setShowShare] = useState(false)

    const [viewRow, setViewRow] = useState(0)

  return (
    <>
        <Layout>
            <Modal show={show} onClose={() => setShow(false)} />
            <ShareModal showShare={showShare} onClose={() => setShowShare(false)} />
            <div className='bg__area__content'>
            <div className='container'>
                    <div className='py-2'>
                        <div className='page_breadcum'>
                            <ul>
                                <li> <Link to="/events"><i class="fas fa-house"></i> Events /</Link> </li>
                                <li className='active'> <Link to="/breed-horses">Bred Horses </Link> </li>
                            </ul>
                        </div>

                        <div className='table__header'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3>Finest Equinedge Talent</h3>
                                <button className='btn btn-default border text-primary' onClick={() => setShowShare(true)}><i class="fas fa-share-alt"></i> Share</button>
                            </div>

                        <div className='table__Tabs'>
                            <div className={`sigle-tab ${tabActive == 1 ? 'active' : ''}`} onClick={() => setTabActive(1)}>All Horses</div>
                            <div className={`sigle-tab ${tabActive == 2 ? 'active' : ''}`} onClick={() => setTabActive(2)}>Favorite Horses</div>
                        </div>
                        <div className='d-flex justify-content-between table__header__row'>
                                <div className='d-flex align-items-center gap-2 second-search__box'>
                                    <div className='d-flex align-items-center mb-2 table__left_searchbtn'>
                                        <input type="text" className='table_search_input form-control flex-md-grow-2' placeholder='Search by sire or dam name' />
                                        <button className='btn btn-primary'>Search</button>
                                    </div>

                                    <div className='d-flex align-items-center mb-2 table__left_searchbtn'>
                                        <input type="text" className='table_search_input form-control flex-md-grow-2' placeholder='Filter by hip number' />
                                        <button className='btn btn-primary'><i class="fas fa-filter" style={{fontSize: 12}}></i> Filter</button>
                                    </div>
                                </div>
                            
                            <div>
                                    <button className='btn btn-default mx-2 border'> <i class="fas fa-filter"></i> Highest to Low</button>
                            </div>
                            </div>



                            {tabActive == 1 ? (
                                <div className='table-responsive'>
                                    <table className='table mt-1 table-hover'>
                                        <tr className='table__head__row'>
                                            <th className='table__tdp' style={{ width: '48px' }}>
                                            </th>
                                            <th className='table__tdp' style={{ width: '189px' }}>Sire & Dam Name</th>
                                            <th className='' style={{ width: '89.75px' }}>New Horse</th>
                                            <th className='' style={{ width: '89.75px' }}>Sex</th>
                                            <th className='' style={{ width: '89.75px' }}>Hip Number</th>
                                            <th className='' style={{ width: '89.75px' }}>Price</th>
                                            <th className='' style={{ width: '89.75px ' }}>Truf</th>
                                            <th className='' style={{ width: '89.75px ' }}>Dart</th>
                                            <th className='' style={{ width: '89.75px ' }}>Synts</th>
                                            <th className='' style={{ width: '89.75px ' }}>Wet</th>
                                            <th className='' style={{ width: '89.75px ' }}>Notes</th>
                                            <th className='' style={{ width: '213px ' }}>Action</th>
                                            {/* <th className='table__tdrh align-right'>Action</th> */}
                                        </tr>
            
                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="far fa-star"></i>
                                            </td>
                                            <td className=''>
                                                Stormfire <br />  Moonlit Whisper
                                            </td>
                                            <td>Thunderheart</td>
                                            <td>Male</td>
                                            <td>Hip 2</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>
                                        {viewRow == 1 &&  <ViewRow />}
                                    
                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="fas fa-star star-fill"></i>
                                            </td>
                                            <td className=''>
                                                Midnight Majesty <br /> Enchantress
                                            </td>
                                            <td>Silvermist</td>
                                            <td>Female</td>
                                            <td>Hip 3</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        {viewRow == 2 &&  <ViewRow />}

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="far fa-star"></i>
                                            </td>
                                            <td className=''>
                                                Thunderstrike <br /> Golden Grace
                                            </td>
                                            <td>Midnight Star</td>
                                            <td>Male</td>
                                            <td>Hip 4</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="fas fa-star star-fill"></i>
                                            </td>
                                            <td className=''>
                                                Noble Knight <br /> Misty Meadows
                                            </td>
                                            <td>Blaze Runner</td>
                                            <td>Male</td>
                                            <td>Hip 5</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="far fa-star"></i>
                                            </td>
                                            <td className=''>
                                                Silver Shadow <br /> Serenity Song
                                            </td>
                                            <td>Duskfall</td>
                                            <td>Male</td>
                                            <td>Hip 6</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="fas fa-star star-fill"></i>
                                            </td>
                                            <td className=''>
                                                Crimson King <br /> Velvet Rose
                                            </td>
                                            <td>Stormrider</td>
                                            <td>Female</td>
                                            <td>Hip 7</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="far fa-star"></i>
                                            </td>
                                            <td className=''>
                                                Golden Arrow <br /> Sapphire Dream
                                            </td>
                                            <td>Amberfire</td>
                                            <td>Male</td>
                                            <td>Hip 8</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="far fa-star"></i>
                                            </td>
                                            <td className=''>
                                                Golden Arrow <br /> Sapphire Dream
                                            </td>
                                            <td>Amberfire</td>
                                            <td>Male</td>
                                            <td>Hip 9</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="far fa-star"></i>
                                            </td>
                                            <td className=''>
                                                Starlight Serenade <br /> Stardust Symphony
                                            </td>
                                            <td>Whispering Wind</td>
                                            <td>Male</td>
                                            <td>Hip 10</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>
            
                                        
                                        
                                    </table>
                                </div>
                            ) : (

                                <div className='table-responsive'>
                                    <table className='table mt-1 table-hover'>
                                        <tr className='table__head__row'>
                                            <th className='table__tdp' style={{ width: '48px' }}>
                                                
                                            </th>
                                            <th className='table__tdp' style={{ width: '189px' }}>Sire & Dam Name</th>
                                            <th className='' style={{ width: '89.75px' }}>New Horse</th>
                                            <th className='' style={{ width: '89.75px' }}>Sex</th>
                                            <th className='' style={{ width: '89.75px' }}>Hip Number</th>
                                            <th className='' style={{ width: '89.75px' }}>Price</th>
                                            <th className='' style={{ width: '89.75px ' }}>Truf</th>
                                            <th className='' style={{ width: '89.75px ' }}>Dart</th>
                                            <th className='' style={{ width: '89.75px ' }}>Synts</th>
                                            <th className='' style={{ width: '89.75px ' }}>Wet</th>
                                            <th className='' style={{ width: '89.75px ' }}>Notes</th>
                                            <th className='' style={{ width: '213px ' }}>Action</th>
                                            {/* <th className='table__tdrh align-right'>Action</th> */}
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="fas fa-star star-fill"></i>
                                            </td>
                                            <td className=''>
                                                Midnight Majesty <br /> Enchantress
                                            </td>
                                            <td>Silvermist</td>
                                            <td>Female</td>
                                            <td>Hip 3</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="fas fa-star star-fill"></i>
                                            </td>
                                            <td className=''>
                                            Noble Knight <br /> Misty Meadows
                                            </td>
                                            <td>Blaze Runner</td>
                                            <td>Male</td>
                                            <td>Hip 5</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                        <tr className='table__row table__row_content'>
                                            <td className='table__tdp'>
                                                <i class="fas fa-star star-fill"></i>
                                            </td>
                                            <td className=''>
                                            Crimson King <br /> Velvet Rose
                                            </td>
                                            <td>Stormrider</td>
                                            <td>Female</td>
                                            <td>Hip 7</td>
                                            <td>2000 USD</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>##</td>
                                            <td>
                                                <div className='table__comment'>
                                                    <img src='./assets/img/icon/chat.svg' style={{
                                                        width: '21.68px',
                                                        height: '21.68px'
                                                    }} />
                                                    <span className='comment__badge'>2</span> </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center gap-2'>
                                                    <button
                                                        className="btn btn-default border site-s-font d-flex justify-content-center gap-3" type="button"
                                                        style={{ width: '107px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                    >

                                                        <span style={{ marginTop: '-2px' }}>Full View</span>
                                                        <i className="fas fa-caret-down" style={{ marginTop: '2px' }}></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-default border site-s-font text-primary d-flex justify-content-center'
                                                        style={{ width: '73px', height: '32px', padding: '5px, 12px, 5px, 12px', gap: '6px' }}
                                                        onClick={() => setShow(true)}>
                                                        <i class="far fa-edit" style={{ marginTop: '2px' }}></i>

                                                        <span style={{ marginTop: '-2px' }}>Edit</span>
                                                    </button>
                                                </div>
                                            </td>
                                            
                                        </tr>

                                    </table>
                                </div>
                            )}
                            {/* Tab - 1 */}
                        


                        

                            <div className='table__pagination'>
                                <span> {`<`} </span>
                                <span className='page-active'>1</span>
                                <span>2</span>
                                <span>3</span>
                                <span>4</span>
                                <span> {`>`} </span>
                            </div>

                        </div>
                    </div>
            </div>
        </div>
        </Layout>
       
    </>
  )
}

export default FavouriteHorses