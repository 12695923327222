/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import cookie from "js-cookie";
import Swal from "sweetalert2";
import { Pagination } from 'antd';

// import { useLocation } from 'react-router';

import { ToastContainer, toast } from "react-toastify";


import Layout from "./Layouts/Layout";
import Modal from "./modal/Modal";
import NotesModal from "./modal/NotesModal";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { baseUrl, API_URL } from "../config/config";

import { HiOutlineHome } from "react-icons/hi";

import ViewBredRow from "./ViewBredRow";
import { logout } from "../features/slices/authslice.";
import { AiFillFilter } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { FaSort } from "react-icons/fa6";
import { BiTrashAlt } from "react-icons/bi";
import SortIcon from "./icons/SortIcon";
import { MdClear } from "react-icons/md";
import TableLoading from "./TableLoading";

const BreedHorses = (...props) => {
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("location.state", location.state);

  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem("auth"));


  const [searchParams] = useSearchParams();
  // console.log('searchParams', searchParams.size);

  const query = queryString.parse(location.search);
  // console.log('queryString', query);

  const [loading, setLoading] = useState(true);
  const [loadArchive, setLoadArchive] = useState(false);
  const [loadDel, setLoadDel] = useState(false);
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState(false);
  const [tabActive, setTabActive] = useState(1);
  const [checkedAll, setCheckedAll] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [newPageSize, setNewPageSize] = useState(10);
  const [archivedPage, setArchivedPage] = useState({
    page: 1,
    size: 10
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalArchived, setTotalArchived] = useState(0);
  const [breedHorsesList, setBreedHorsesList] = useState([]);
  const [archivedHorsesList, setArchivedHorsesList] = useState([]);
  const [horses, setHorses] = useState([]);
  const [horsesArchive, setHorsesArchive] = useState([]);
  const [horsesEdit, setHorsesEdit] = useState([]);
  const [highestStatus, setHighestStatus] = useState(false);
  const [sort, setSort] = useState(true);
  const [sortPage, setSortPage] = useState(true);
  const [gsrSort, setGsrSort] = useState("");
  const [highestSort, setHighestSort] = useState("");
  const [foalingInfo, setFoalingInfo] = useState("");
  const [foalingStatus, setFoalingStatus] = useState(false);

  const [searchErr, setSearchErr] = useState(false);

  const [breedStatus, setBreedStatus] = useState("get");

  const [search, setSearch] = useState({
    name: "",
  });

  const [searchBy, setSearchBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  /* Notes*/
  const [noteShow, setNoteShow] = useState(false);
  /* Notes*/

  const updateSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const getArchivedHorses = async (page, size) => {


    setLoading(true);
    // /index?filterBy=isArchived&filterValue=false

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    try {
      const ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${query.searchBy}&searchTerm=${query.searchTerm}`,
        requestOptions
      );
      const result = await ArchivedHorses.json();

      // console.log('getArchivedHorses', result);
      const archivedList = result?.successData?.result;
      setTotalArchived(result?.successData?.totalArchived)
      setArchivedHorsesList(archivedList);

    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  const getBreedHorses = async (page, size) => {
    setLoading(true);
    let result;
    let requestOptions;
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    // console.log('searchParams.size ', searchParams.size);
    // console.log('searchBy', searchBy.length);

    if (searchBy?.length > 0 || searchTerm?.length > 0) {
      setBreedStatus("breed");

      requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      setSearchBy(searchBy);
      setSearchTerm(searchTerm);


      // `${API_URL}/BreedHorses/index?orderBy=name&orderByDesc=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,

      const BreedHorses = await fetch(
        `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&orderBy=name&orderByDesc=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );

      result = await BreedHorses.json();

      // console.log("result ", result);

      if (BreedHorses.status === 401) {
        dispatch(logout());
      } else {
        setBreedHorsesList(result.successData);
        getArchivedHorses(archivedPage.page, archivedPage.size);
      }

    } else if (searchParams.size <= 0 || searchParams.size == undefined) {

      if (location.state == null) {
        setBreedStatus("breed");

        setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

        requestOptions = {
          method: "GET",
          headers: headers,
          redirect: "follow",
        };

        let BreedHorses;

        if (search.name == "") {
          BreedHorses = await fetch(
            `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&searchBy=sireOrDamName&searchTerm=${search.name}&orderBy=name&orderByDesc=true&page=${page}&size=${size}`,
            requestOptions
          );
        } else
          BreedHorses = await fetch(
            `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&orderBy=name&orderByDesc=true&page=${page}&size=${size}`,
            requestOptions
          );

        if (BreedHorses.status === 401) {
          dispatch(logout());
        }

        result = await BreedHorses.json();

        // console.log('result ', result);

        if (BreedHorses.status === 401) {
          dispatch(logout());
        }
      } else {
        setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);
        requestOptions = {
          method: "GET",
          headers: headers,
          redirect: "follow",
        };

        // "searchBy": "HorseIds",
        // "searchTerm": "392,393,394,391,395"
        // console.log('location.state',  location.state)

        setSearchBy(location.state.searchBy);
        setSearchTerm(location.state.searchTerm);

        let BreedHorses;

        if (search.name == "") {
          BreedHorses = await fetch(
            `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&orderBy=name&orderByDesc=true&page=${page}&size=${size}&searchBy=${location.state.searchBy}&searchTerm=${location.state.searchTerm}`,
            requestOptions
          );
        } else {
          BreedHorses = await fetch(
            `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&orderBy=name&orderByDesc=true&page=${page}&size=${size}&searchBy=${location.state.searchBy}&searchTerm=${location.state.searchTerm}`,
            requestOptions
          );
        }

        if (BreedHorses.status === 401) {
          dispatch(logout());
        }

        result = await BreedHorses.json();

        console.log("result", result);

        // result = {
        //     isSuccess: true,
        //     successData: {
        //         result: location.state.result,
        //         totalArchived: location.state.totalArchived,
        //         totalItems: location.state.totalItems,
        //         pageSize: location.state.pageSize,
        //     },
        // }
      }
    } else {

      setBreedStatus("breed");

      setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

      requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const BreedHorses = await fetch(
        `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&orderBy=name&orderByDesc=true&page=${page}&size=${size}&searchBy=${query.searchBy}&searchTerm=${query.searchTerm}`,
        requestOptions
      );

      result = await BreedHorses.json();

      console.log("result ", result);

      if (BreedHorses.status === 401) {
        dispatch(logout());
      } else {
        setBreedHorsesList(result.successData.result);
        getArchivedHorses(archivedPage.page, archivedPage.size);
        //isArchived
      }

      // setLoading(false);

    }

    // console.log("breed result", result);
    // setCurrentPage(result.successData.currentPage);

    setCurrentPage(page);
    if (result.isSuccess == false) {
      setTotalArchived(0);
      setTotalItems(0);
      setPageSize(0);
      setNewPageSize(0);
      setBreedHorsesList([]);
    } else {
      setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
      setTotalItems(result.successData.totalItems);
      setPageSize(result.successData.pageSize);
      setNewPageSize(result.successData.pageSize);
      setBreedHorsesList(result.successData.result);
      getArchivedHorses(archivedPage.page, archivedPage.size);
    }
    // console.log('result', result);
    // console.log('successData', result.successData.result);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (query.searchBy == "") {
      setSearchBy("");
    } else {
      setSearchBy(query.searchBy);
    }
    if (query.searchBy == "") {
      setSearchTerm("");
    } else {
      setSearchTerm(query.searchTerm);
    }
    getBreedHorses(currentPage, pageSize);
    localStorage.setItem("horses", JSON.stringify([]));
  }, []);

  const getEventSearch = async (e, page, size) => {
    setBreedStatus("search");
    e.preventDefault();
    setLoading(true);

    setHighestSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    if (search.name == "") {
      // setSearchErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Sire or Dam Name.',
      // })
      getBreedHorses(page, size);
      getArchivedHorses(archivedPage.page, archivedPage.size);
    } else {
      setSearchErr(false);


      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}`,
        requestOptions
      );
      const result = await searchInfo.json();




      if (searchInfo.status === 401) {
        dispatch(logout());
      }

      const ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${archivedPage.page}&size=${archivedPage.size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(currentPage);

      if (result.successData == "") {
        setTotalArchived(0);
        setTotalItems(0);
        // setPageSize(0);
        // setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(page);
        setPageSize(pageSize);
        setNewPageSize(pageSize);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }

      setLoading(false);
    }
  };

  const getEventSearchPage = async (e, page, size) => {
    setBreedStatus("search");
    e.preventDefault();
    setLoading(true);

    setHighestSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    if (search.name == "") {
      // setSearchErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Sire or Dam Name.',
      // })
      getBreedHorses(1, 10);
    } else {
      setSearchErr(false);


      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
        requestOptions
      );

      const result = await searchInfo.json();

      if (searchInfo.status === 401) {
        dispatch(logout());
      }

      console.log("searchInfo result", result);

      setCurrentPage(page);

      if (result.successData == "") {
        setTotalArchived(0);
        setTotalItems(0);
        // setPageSize(0);
        // setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(page);
        setPageSize(pageSize);
        setNewPageSize(pageSize);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }


      const ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${archivedPage.page}&size=${archivedPage.size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setLoading(false);
    }
  };

  const getEventSearchPagePre = async (page, size) => {
    setBreedStatus("search");
    setLoading(true);

    setHighestSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    if (search.name == "") {
      // setSearchErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Sire or Dam Name.',
      // })
      getBreedHorses(1, 10);
    } else {
      setSearchErr(false);


      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      const searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
        requestOptions
      );

      const result = await searchInfo.json();

      if (searchInfo.status === 401) {
        dispatch(logout());
      }

      console.log("searchInfo result", result);

      setCurrentPage(page);

      if (result.successData == "") {
        setTotalArchived(0);
        setTotalItems(0);
        // setPageSize(0);
        // setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(page);
        setPageSize(pageSize);
        setNewPageSize(pageSize);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }


      const ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${archivedPage.page}&size=${archivedPage.size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setLoading(false);
    }
  };

  const getFiltersHighestLowest = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setBreedStatus("highestLowest");
    setGsrSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    setHighestSort("Price");
    setHighestStatus(info);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}&orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {
      console.log("searchInfo result", result);

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setPageSize(0);
        setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
        setPageSize(result.successData.pageSize);
        setNewPageSize(result.successData.pageSize);
      }
    }

    let ArchivedHorses;

    if (search.name == "") {
      ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}&orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const ArchivedResult = await ArchivedHorses.json();
    if (ArchivedResult.successData == "") {
      setArchivedHorsesList([]);
    } else {
      const archivedList = ArchivedResult?.successData?.result;
      setTotalArchived(ArchivedResult?.successData?.totalArchived)
      setArchivedHorsesList(archivedList);
    }


    setLoading(false);
  };

  const getFiltersHighestLowestPre = async (page, size, info) => {
    setLoading(true);
    setBreedStatus("highestLowest");
    setGsrSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    setHighestSort("Price");
    setHighestStatus(info);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}&orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {
      console.log("searchInfo result", result);

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setPageSize(0);
        setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
        setPageSize(result.successData.pageSize);
        setNewPageSize(result.successData.pageSize);
      }
    }

    let ArchivedHorses;

    if (search.name == "") {
      ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      ArchivedHorses = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}&orderBy=Price&orderByDesc=${info}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const ArchivedResult = await ArchivedHorses.json();
    if (ArchivedResult.successData == "") {
      setArchivedHorsesList([]);
    } else {
      const archivedList = ArchivedResult?.successData?.result;
      setTotalArchived(ArchivedResult?.successData?.totalArchived)
      setArchivedHorsesList(archivedList);
    }


    setLoading(false);
  };

  const getFiltersDate = async (e, page, size, foaling, info) => {
    e.preventDefault();
    setLoading(true);
    setBreedStatus("Date");
    setGsrSort("");


    setFoalingStatus(info);
    setFoalingInfo(foaling);


    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    // setHighestSort("Price");
    // setHighestStatus(info);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setPageSize(0);
        setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
        setPageSize(result.successData.pageSize);
        setNewPageSize(result.successData.pageSize);
      }
    }
    setLoading(false);
  };

  const getFiltersDatePre = async (page, size, foaling, info) => {

    setLoading(true);
    setBreedStatus("Date");
    setGsrSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    // setHighestSort("Price");
    //setHighestStatus(info);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setPageSize(0);
        setNewPageSize(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
        setPageSize(result.successData.pageSize);
        setNewPageSize(result.successData.pageSize);
      }
    }
    setLoading(false);
  };

  const getFiltersSort = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setBreedStatus("filtersSort ");
    setHighestSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    setSortPage(info);

    let newSort = info == true ? false : true;
    setSort(newSort);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=Price&orderByDesc=${info}&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}&orderBy=name&orderByDesc=${newSort}&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }
    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {
      console.log("searchInfo result", result);

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }
    }
    setLoading(false);
  };

  const getFiltersSortPage = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setBreedStatus("filtersSort");
    setHighestSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=name&orderByDesc=${newSort}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}orderBy=name&orderByDesc=${newSort}&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setBreedHorsesList([]);
        setArchivedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }
    }
    setLoading(false);
  };
  const getFiltersSortPagePre = async (page, size, info) => {

    setLoading(true);
    setBreedStatus("filtersSort");
    setHighestSort("");

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);


    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=name&orderByDesc=${newSort}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?searchBy=sireOrDamName&searchTerm=${search.name}orderBy=name&orderByDesc=${newSort}&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${foaling}&orderByDesc=${info}&searchBy=sireOrDamName;${searchBy}&searchTerm=${search.name};${searchTerm}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setBreedHorsesList([]);
        setArchivedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }
    }
    setLoading(false);
  };

  const getFiltersGsrSort = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setBreedStatus("filtersGsrSort");
    setHighestSort("");

    setGsrSort(info);
    setSortPage(sort);

    let newSort;

    if (gsrSort == info) {
      newSort = sort == true ? false : true;
    } else {
      newSort = true;
    }

    setSort(newSort);

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    let searchInfo;

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${newSort}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${newSort}&searchBy=sireOrDamName&searchTerm=${search.name}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${newSort}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${newSort}&searchBy=sireOrDamName&searchTerm=${search.name}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }
    }
    setLoading(false);
  };

  const getFiltersGsrSortPage = async (e, page, size, new_sort, info) => {
    e.preventDefault();
    setLoading(true);
    setBreedStatus("filtersGsrSort");

    setSortPage(new_sort);

    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&searchBy=sireOrDamName&searchTerm=${search.name}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&searchBy=sireOrDamName&searchTerm=${search.name}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }
    }
    setLoading(false);
  };

  const getFiltersGsrSortPagePre = async (page, size, new_sort, info) => {
    setLoading(true);
    setBreedStatus("filtersGsrSort");

    setSortPage(new_sort);

    
    setSearchQuery(searchBy === "" || searchTerm === undefined ? false : true);

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    if (search.name == "") {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    } else {
      searchInfo = await fetch(
        `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&searchBy=sireOrDamName&searchTerm=${search.name}&filterBy=isArchived&filterValue=false&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
        requestOptions
      );
    }

    const result = await searchInfo.json();

    if (searchInfo.status === 401) {
      dispatch(logout());
    } else {

      let ArchivedHorses;

      if (search.name == "") {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
          requestOptions
        );
      } else {
        ArchivedHorses = await fetch(
          `${API_URL}/BreedHorses/index?orderBy=${info}&orderByDesc=${sort}&searchBy=sireOrDamName&searchTerm=${search.name}&filterBy=isArchived&filterValue=true&page=${page}&size=${size}&searchBy=${searchBy}&searchTerm=${searchTerm}`,
          requestOptions
        );
      }

      const ArchivedResult = await ArchivedHorses.json();
      if (ArchivedResult.successData == "") {
        setArchivedHorsesList([]);
      } else {
        const archivedList = ArchivedResult?.successData?.result;
        setTotalArchived(ArchivedResult?.successData?.totalArchived)
        setArchivedHorsesList(archivedList);
      }

      setCurrentPage(page);
      if (result.isSuccess == false) {
        setTotalArchived(0);
        setTotalItems(0);
        setBreedHorsesList([]);
      } else {
        setTotalArchived(result.successData.totalArchived == null ? 0 : result.successData.totalArchived);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
      }
    }
    setLoading(false);
  };


  const handleChange = (e) => {
    // console.log('checked', e.target.checked);
    // console.log('value', e.target.value);
    let horses = { horseId: Number(e.target.value) };

    if (e.target.checked) {
      setHorses((olddata) => [...olddata, { horseId: Number(e.target.value) }]);
    } else {
      setHorses(horses.filter((item) => item.id !== Number(e.target.value)));
    }
  };

  const handleAllChange = (e) => {
    setCheckedAll(e.target.checked);
    let horsesList = [];

    if (e.target.checked) {
      breedHorsesList.forEach((item) => {
        if (item?.id == null) {
        } else {
          horsesList.push({
            id: item.id,
            sireName: item.sireName,
            damName: item.damName,
            hipNumber: item.hipNumber,
            sex: item.sex,
            name: item.name,
            price: item.price,
            consignor: item.consignor,
            horseId: Number(item.id),
            isArchived: item.isArchived,
          });
        }
      });
    } else {
      horsesList = [];
    }
    setHorses(horsesList);
  };

  const handleAllChangeArchived = (e) => {
    setCheckedAll(e.target.checked);
    let horsesList = [];

    if (e.target.checked) {
      archivedHorsesList.forEach((item) => {
        if (item?.id == null) {
        } else {
          horsesList.push({
            id: item.id,
            sireName: item.sireName,
            damName: item.damName,
            hipNumber: item.hipNumber,
            sex: item.sex,
            name: item.name,
            price: item.price,
            consignor: item.consignor,
            horseId: Number(item.id),
            isArchived: item.isArchived,
          });
        }
      });
    } else {
      horsesList = [];
    }
    setHorsesArchive(horsesList);
  };

  const ClearAll = () => {
    getBreedHorses(1, 10);
    setSearch({ ...search, name: "" });
  };

  const handlePagination = (page, size) => {
    setCurrentPage(page);
    setPageSize(size)

    if (breedStatus == "breed") {
      getBreedHorses(page, size);
    } else if (breedStatus == "get") {
      getBreedHorses(page, size);
    } else if (breedStatus == "Date") {
      getFiltersDatePre(page, size, foalingInfo, foalingStatus);
    } else if (breedStatus == "search") {
      getEventSearchPagePre(page, size);
    } else if (breedStatus == "highestLowest") {
      getFiltersHighestLowestPre(page, size, highestStatus);
    } else if (breedStatus == "filtersSort") {
      getFiltersSortPagePre(page, size, sort);
    } else if (breedStatus == "filtersGsrSort") {
      getFiltersGsrSortPagePre(page, size, sortPage, gsrSort);
    }
  };

  const handleArchivedPagination = (page, size) => {
    setArchivedPage({ ...archivedPage, page: page, size: size });
    getArchivedHorses(page, size);
  }


  const handleAddToEvent = (data) => {
    if (data == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
    } else {
      // cookie.set("horses", JSON.stringify(horses));
      localStorage.setItem("horses", JSON.stringify(data));
      navigate("/add-to-event");
    }
  };

  const handleArchive = async (info, data) => {
    setLoadArchive(true);
    if (data == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
      setLoadArchive(false);
    } else {
      try {
        let newHorses = [];
        data.forEach((item) => {
          newHorses.push({ id: item.horseId, shouldArchive: info });
        })

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
            horses: newHorses,
          }),
          headers: headers,
          redirect: 'follow'
        };

        const addArchive = await fetch(`${API_URL}/horse/archive`, requestOptions);
        const archiveResult = await addArchive.json();
        if (addArchive.status === 401) {
          dispatch(logout());
        } else {
          if (archiveResult.isSuccess == true) {
            getBreedHorses(currentPage, pageSize);
            Swal.fire({
              icon: 'success',
              title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Been ${info ? 'Archived' : 'Unarchived'} Successfully!`,
            });

            if (info == true) {
              setHorses([]);
            } else {
              setHorsesArchive([]);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    setLoadArchive(false);
  };


  const handleDelete = async () => {
    setLoadDel(true);
    if (horses == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
    } else {
      Swal.fire({
        title: 'Are You Sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete It!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let horsesId = horses.map((item) => item.horseId);
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${auth.token}`);

            const requestOptions = {
              method: 'POST',
              body: JSON.stringify(horsesId),
              headers: headers,
              redirect: 'follow'
            };

            const HorseDelete = await fetch(`${API_URL}/Horse/delete-multiple`, requestOptions);
            const deleteResult = await HorseDelete.json();

            console.log({ deleteResult });

            if (HorseDelete.status === 401) {
              dispatch(logout());
            } else {
              if (deleteResult.isSuccess == true) {
                getBreedHorses(currentPage, pageSize);
                Swal.fire({
                  icon: 'success',
                  title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Been Deleted Successfully!`,
                })
                setHorses([]);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Not Been Deleted Successfully!`,
                })
              }
            }
          } catch (err) {
            console.log(err);
          }

        }
      })
    }
    setTimeout(() => {
      setLoadDel(false);
    }, 2500)
  };

  // console.log("archivedHorsesList", archivedHorsesList);
  console.log("totalI tems", totalItems);
  console.log("total Archived", totalArchived);
  console.log("total searchQuery", searchQuery);
  console.log("total searchBy", searchBy);

  return (
    <>
      <Layout>
        <Modal
          show={show}
          onClose={() => setShow(false)}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handlePagination={handlePagination}
        />
        <NotesModal
          noteShow={noteShow}
          setNoteShow={setNoteShow}
          onClose={() => setNoteShow(false)}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handlePagination={handlePagination}
        />
        <div className="bg__area__content">
          <div className="container">
            <div className="py-3">
              <div className="page_breadcum">
                <ul>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Link to="/breed-horses">
                      <HiOutlineHome
                        style={{
                          color: "rgba(140, 140, 140, 1)",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginTop: "-4px",
                        }}
                      />{" "}
                      Breeding /
                    </Link>
                  </li>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    className="active text-dark"
                  >
                    {" "}
                    <a href="">
                      {searchQuery ? "Bred Results" : "Bred Horses"}
                    </a>{" "}
                  </li>
                </ul>
              </div>

              <div className="table__header shadow-sm">
                <h3 className="py-2">
                  {searchQuery ? "Bred Results" : "Bred Horses"}
                </h3>


                <div className="table__Tabs">
                  <div
                    className={`sigle-tab ${tabActive == 1 ? "active" : ""}`}
                    onClick={() => setTabActive(1)}
                  >
                    All Horses {searchQuery ? "" : `(${totalItems})`}
                  </div>
                  <div
                    className={`sigle-tab ${tabActive == 2 ? "active" : ""}`}
                    onClick={() => setTabActive(2)}
                  >
                    Archive Horses {searchQuery ? "" : `(${totalArchived})`}
                  </div>
                </div>

                <div className="row d-flex justify-content-between table__header__row ">
                  <div className="col-sm-12 col-xxl-6 d-flex align-items-center mb-2 table__left_searchbtn gap-2">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 mb-2">
                        <form
                          className="w-100 d-flex align-items-center gap-2"
                          onSubmit={(e) => getEventSearch(e, 1, 10)}
                          style={{ width: "378px" }}
                        >
                          <div className="table_search_input input-group form-group position-relative">
                            <input
                              type="text"
                              className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""
                                }`}
                              name="name"
                              value={search.name}
                              onChange={updateSearch}
                              placeholder="Search by sire or dam name"
                            />
                            <span
                              className="input-group-append position-absolute"
                              style={{
                                zIndex: "10",
                                marginTop: "-2px",
                                right: "-7px",
                              }}
                            >
                              {search.name.length > 0 ? (
                                <div className="input-group-text border-0 bg-transparent ml-n5">
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    onClick={ClearAll}
                                  >
                                    <MdClear className="clear_logo" />
                                  </span>
                                </div>
                              ) : null}
                            </span>
                          </div>
                          <button
                            className="btn btn-primary"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "22px",
                              marginRight: "5px",
                            }}
                          >
                            Search
                          </button>
                        </form>
                      </div>
                      <div className="col-sm-12 col-md-6" style={{ marginTop: "-6px" }}>
                        <div className="sort_button mt-2 gap-1" style={{ float: "left" }}>
                          <div
                            className="dropdown"
                            style={{ width: "155px", height: "40px" }}
                          >
                            <button
                              className="btn btn-default mx-2 mb-2 border d-flex align-items-center justify-content-center text-center"
                              type="button"
                              data-bs-toggle="dropdown"
                              style={{ width: "155px", height: "40px" }}
                            >
                              <AiFillFilter
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                }}
                              >
                                Sort by Date
                              </span>
                            </button>
                            <ul
                              class="dropdown-menu border-start border-end border-bottom border-top-0"
                              style={{ width: "136px !important" }}
                            >
                              <li
                                onClick={(e) =>
                                  getFiltersDate(e, currentPage, pageSize, "sireFoalingDate", true)
                                }
                              >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Sire Oldest to Newest
                                </a>
                              </li>
                              <li
                                onClick={(e) =>
                                  getFiltersDate(e, currentPage, pageSize, "sireFoalingDate", false)
                                }
                              >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Sire Newest to Oldest
                                </a>
                              </li>
                              <li
                                onClick={(e) =>
                                  getFiltersDate(e, currentPage, pageSize, "damFoalingDate", true)
                                }
                              >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Dam Oldest to Newest
                                </a>
                              </li>
                              <li
                                onClick={(e) =>
                                  getFiltersDate(e, currentPage, pageSize, "damFoalingDate", false)
                                }
                              >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Dam Newest to Oldest
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div
                            className="dropdown"
                            style={{ width: "155px !important" }}
                          >
                            <button
                              className="btn btn-default mx-2 mb-2 border d-flex align-items-center justify-content-center text-center"
                              type="button"
                              data-bs-toggle="dropdown"
                              style={{ width: "155px", height: "40px" }}
                            >
                              <AiFillFilter
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                }}
                              >
                                Sort by Price
                              </span>
                            </button>
                            <ul
                              class="dropdown-menu border-start border-end border-bottom border-top-0"
                              style={{ width: "146px !important" }}
                            >
                              <li
                                onClick={(e) =>
                                  getFiltersHighestLowest(e, currentPage, pageSize, true)
                                }
                              >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Highest to Lowest
                                </a>
                              </li>
                              <li
                                onClick={(e) =>
                                  getFiltersHighestLowest(e, currentPage, pageSize, false)
                                }
                              >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Lowest to Highest
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-6 d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center gap-2" style={{ float: "left" }}>

                      {
                        tabActive == 1 ? <>
                          {
                            loadArchive ?
                              <span
                                style={{
                                  color: `#000`,
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                                className="btn btn-outline-primary" disabled>
                                Processing...
                              </span> :
                              <span
                                className="btn btn-outline-primary"
                                onClick={() => handleArchive(true, horses)}
                                style={{
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              >
                                Archive ({horses.length})
                              </span>
                          }
                        </> : null
                      }
                      {
                        tabActive == 2 ? <>
                          {
                            loadArchive ?
                              <span
                                style={{
                                  color: `#000`,
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                                className="btn btn-outline-primary" disabled>
                                Processing...
                              </span> :
                              <span
                                className="btn btn-outline-primary"
                                onClick={() => handleArchive(false, horsesArchive)}
                                style={{
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              >
                                Unarchive ({horsesArchive.length})
                              </span>
                          }
                        </> : null
                      }
                      {
                        tabActive == 1 ? <>
                          {
                            loadDel ?
                              <span
                                style={{
                                  color: `white`,
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                                className="btn btn-danger" disabled>
                                Processing...
                              </span> :
                              <span
                                className="btn btn-danger d-flex align-items-center justify-content-center text-center"
                                onClick={handleDelete}
                                style={{
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              >
                                <BiTrashAlt
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    marginRight: "5px",
                                  }}
                                />
                                <span>
                                  Delete ({horses.length})
                                </span>
                              </span>
                          }
                        </> : null
                      }
                      <span
                        className="btn btn-primary"
                        onClick={() => handleAddToEvent(tabActive == 1 ? horses : horsesArchive)}
                        style={{
                          width: "140px",
                          height: "40px",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                        }}
                      >
                        Add to Event
                      </span>
                    </div>
                  </div>
                </div>


                <div className="table-responsive table__fix_head mt-1">
                  <table
                    className="table"
                    style={{
                      width: "100%",
                      maxHeight: "300px !important",
                      position: "relative",
                    }}
                  >
                    <tr
                      className="table__head__row"
                      style={{ height: "45px !important", zIndex: "50" }}
                    >
                      <th
                        className="table__tdp"
                        style={{ width: "60px", paddingLeft: "10px" }}
                      >
                        {
                          tabActive == 1 ? <>
                            <input
                              type="checkbox"
                              onChange={handleAllChange}
                              checked={
                                loading
                                  ? false
                                  : breedHorsesList.length == 0
                                    ? false
                                    : horses.length === breedHorsesList.length
                              }
                            />
                          </> : null
                        }
                        {
                          tabActive == 2 ? <>
                            <input
                              type="checkbox"
                              onChange={handleAllChangeArchived}
                              checked={
                                loading
                                  ? false
                                  : archivedHorsesList.length == 0
                                    ? false
                                    : horsesArchive.length === archivedHorsesList.length
                              }
                            /></> : null
                        }
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "60px", paddingLeft: "0px" }}
                      >
                        ID
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "178px", paddingLeft: "0px" }}
                      >
                        Sire Name
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "178px", paddingLeft: "0px" }}
                      >
                        Dam Name
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "161px", paddingLeft: "0px" }}
                      >
                        New Horse
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "60px", paddingLeft: "0px" }}
                      >
                        Sex
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "80px", paddingLeft: "0px" }}
                      >
                        Hip
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "90px !important", paddingLeft: "0px" }}
                        onClick={(e) => getFiltersGsrSort(e, 1, pageSize, "GsrDirt")}
                      >
                        <span
                          className="d-flex align-items-center justify-content-start"
                          style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "#FFF0",
                            marginLeft: "-7px",
                          }}
                        >
                          Dirt{""}
                          {gsrSort == "GsrDirt" ? (
                            <SortIcon
                              select={gsrSort == "GsrDirt" ? true : false}
                              sort={sort}
                            />
                          ) : (
                            <SortIcon select={null} sort={sort} />
                          )}
                        </span>
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "90px !important", paddingLeft: "0px" }}
                        onClick={(e) => getFiltersGsrSort(e, 1, pageSize, "GsrTurf")}
                      >
                        <span
                          className="d-flex align-items-center justify-content-start"
                          style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "#FFF0",
                            marginLeft: "-7px",
                          }}
                        >
                          Turf{" "}
                          {gsrSort == "GsrTurf" ? (
                            <SortIcon
                              select={gsrSort === "GsrTurf" ? true : false}
                              sort={sort}
                            />
                          ) : (
                            <SortIcon select={null} sort={sort} />
                          )}
                        </span>
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "90px !important", paddingLeft: "0px" }}
                        onClick={(e) => getFiltersGsrSort(e, 1, pageSize, "GsrSynth")}
                      >
                        <span
                          className="d-flex align-items-center justify-content-start"
                          style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "#FFF0",
                            marginLeft: "-7px",
                          }}
                        >
                          Synth{" "}
                          {gsrSort == "GsrSynth" ? (
                            <SortIcon
                              select={gsrSort == "GsrSynth" ? true : false}
                              sort={sort}
                            />
                          ) : (
                            <SortIcon select={null} sort={sort} />
                          )}
                        </span>
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "90px !important", paddingLeft: "0px" }}
                        onClick={(e) => getFiltersGsrSort(e, 1, pageSize, "GsrWet")}
                      >
                        <span
                          className="d-flex align-items-center justify-content-start"
                          style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "#FFF0",
                            marginLeft: "-7px",
                          }}
                        >
                          Wet{" "}
                          {gsrSort == "GsrWet" ? (
                            <SortIcon
                              select={gsrSort == "GsrWet" ? true : false}
                              sort={sort}
                            />
                          ) : (
                            <SortIcon select={null} sort={sort} />
                          )}
                        </span>
                      </th>
                      <th
                        className="table__tdp"
                        style={{
                          width: "100px !important",
                          paddingLeft: "0px",
                        }}
                        onClick={(e) => {
                          highestStatus
                            ? getFiltersHighestLowest(e, currentPage, pageSize, false)
                            : getFiltersHighestLowest(e, currentPage, pageSize, true);
                        }}
                      >
                        <span
                          className="d-flex align-items-center justify-content-start"
                          style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "#FFF0",
                            marginLeft: "-7px",
                          }}
                        >
                          Price{" "}
                          {highestSort == "Price" ? (
                            <SortIcon
                              select={highestSort == "Price" ? true : false}
                              sort={highestStatus}
                            />
                          ) : (
                            <SortIcon select={null} sort={highestStatus} />
                          )}
                        </span>
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "70px", paddingLeft: "0px" }}
                      >
                        Notes
                      </th>
                      <th
                        className="table__tdp"
                        style={{ width: "213px", paddingLeft: "0px" }}
                      >
                        Action
                      </th>
                    </tr>
                    {
                      loading ?
                        <tr className="table__row table__row_content table-loading">
                          <td className="text-center" colSpan={14}>
                            <TableLoading />
                          </td>
                        </tr> : <>

                          {
                            tabActive == 1 ? <>
                              {
                                breedHorsesList == "" ?
                                  <tr className="table__row table__row_content">
                                    <td className="text-center" colSpan={14}>
                                      <div
                                        className="w-100 d-flex justify-content-center"
                                        style={{ height: "55vh" }}
                                      >
                                        <img
                                          src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                          alt=""
                                          style={{ width: "150px" }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  :
                                  <>
                                    {breedHorsesList.map((item, index) => (
                                      <ViewBredRow
                                        key={index}
                                        item={item}
                                        setShow={setShow}
                                        noteShow={noteShow}
                                        setNoteShow={setNoteShow}
                                        handleChange={handleChange}
                                        setCheckedAll={setCheckedAll}
                                        horses={horses}
                                        setHorses={setHorses}
                                        horsesEdit={horsesEdit}
                                        setHorsesEdit={setHorsesEdit}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        handlePagination={handlePagination}
                                      />
                                    ))}
                                  </>
                              }
                            </> : null
                          }

                          {
                            tabActive == 2 ? <>
                              {
                                archivedHorsesList == "" ?
                                  <tr className="table__row table__row_content">
                                    <td className="text-center" colSpan={14}>
                                      <div
                                        className="w-100 d-flex justify-content-center"
                                        style={{ height: "55vh" }}
                                      >
                                        <img
                                          src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                          alt=""
                                          style={{ width: "150px" }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  :
                                  <>
                                    {archivedHorsesList.map((item, index) => (
                                      <ViewBredRow
                                        key={index}
                                        item={item}
                                        setShow={setShow}
                                        noteShow={noteShow}
                                        setNoteShow={setNoteShow}
                                        handleChange={handleChange}
                                        setCheckedAll={setCheckedAll}
                                        horses={horsesArchive}
                                        setHorses={setHorsesArchive}
                                        horsesEdit={horsesEdit}
                                        setHorsesEdit={setHorsesEdit}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        handlePagination={handlePagination}
                                      />
                                    ))}
                                  </>
                              }
                            </> : null
                          }
                        </>
                    }
                  </table>
                </div>

                {
                  tabActive == 1 ? <>
                    {location.state == null ? (
                      <>
                        {loading ? null : (
                          <>
                            {breedHorsesList == "" ? null : (
                              <div className="table__pagination mt-3">
                                <Pagination
                                  total={totalItems}
                                  onChange={(page, pageSize) => handlePagination(page, pageSize)}
                                  defaultPageSize={pageSize}
                                  defaultCurrent={currentPage}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </> : null
                }

                {
                  tabActive == 2 ? <>
                    {loading ? null : (
                      <>
                        {archivedHorsesList == "" ? null : (
                          <div className="table__pagination mt-3">
                            <Pagination
                              total={totalArchived}
                              onChange={(page, pageSize) => handleArchivedPagination(page, pageSize)}
                              defaultPageSize={archivedPage.size}
                              defaultCurrent={archivedPage.page}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </> : null
                }


              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BreedHorses;
