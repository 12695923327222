/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from 'react';
import moment from "moment";
import Swal from 'sweetalert2';

import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { ToastContainer, toast } from "react-toastify";
import { baseUrl, API_URL } from '../../config/config';
import ListGroup from 'react-bootstrap/ListGroup';
import { logout } from '../../features/slices/authslice.';

const Modal = ({ show, onClose, horsesEdit, setHorsesEdit, pageSize, currentPage, setCurrentPage, handlePagination }) => {
    if (!show) return;

    console.log('horsesEdit', horsesEdit);

    const lenRef = useRef(null);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [isSireLoad, setIsSireLoad] = useState(true);
    const [sireShow, setSireShow] = useState(false)
    const [sireData, setSireData] = useState([])
    const [sireInput, setSireInput] = useState({
        regID: "",
        name: "",
        date: "",
    })

    const [isDamLoad, setIsDamLoad] = useState(true)
    const [damShow, setDamShow] = useState(false)
    const [damData, setDamData] = useState([])
    const [damInput, setDamInput] = useState({
        regID: "",
        name: "",
        date: "",
    })

    const [noteShow, setNoteShow] = useState(false);
    const [note, setNote] = useState({
        note: "",
        horseId: horsesEdit?.id == '' ? "" : horsesEdit?.id,
    });
    const [noteErroer, setNoteError] = useState(false);

    const [errors, setErrors] = useState({
        hipNumber: "",
        name: "",
        price: "",
        consignor: "",
    });

    const [editHorses, setEditHorses] = useState({
        id: horsesEdit?.id,
        sireName: horsesEdit?.sireName,
        damName: horsesEdit?.damName,
        name: horsesEdit?.name,
        sex: horsesEdit?.sex,
        hipNumber: horsesEdit?.hipNumber,
        consignor: horsesEdit?.consignor,
        price: horsesEdit?.price,
    });

    const getSireList = async (e) => {
        setIsSireLoad(true);
        setSireInput({ ...sireInput, [e.target.name]: e.target.value });
        setEditHorses({ ...editHorses, sireName: e.target.value });

        setSireShow(true);
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const getBreedHorses = await fetch(`${API_URL}/BreedHorses/SearchByHorseName?horseName=${e.target.value}&isSire=true`, requestOptions);
        const result = await getBreedHorses.json();

        if (getBreedHorses.status === 401) {
            dispatch(logout());
        }

        setSireData(result.successData);
        setIsSireLoad(false);

        // console.log('result', result.successData);
    };

    const addSireInfo = (regID, name, date,) => {
        // console.log('name', name);
        setSireShow(false);
        setEditHorses({ ...editHorses, sireName: name });
    };

    const getDamList = async (e) => {
        setIsDamLoad(true)
        setDamInput({ ...damInput, [e.target.name]: e.target.value });
        setEditHorses({ ...editHorses, damName: e.target.value });

        setDamShow(true);
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const getBreedHorses = await fetch(`${API_URL}/BreedHorses/SearchByHorseName?horseName=${e.target.value}&isSire=false`, requestOptions);
        const result = await getBreedHorses.json();

        if (getBreedHorses.status === 401) {
            dispatch(logout());
        }

        setDamData(result.successData);
        setIsDamLoad(false)
    };

    const addDamInfo = (regID, name, date,) => {
        setDamShow(false);
        setEditHorses({ ...editHorses, damName: name });
    };

    const updateHorses = (e) => {
        setEditHorses({ ...editHorses, [e.target.name]: e.target.value });
    };

    const updateNote = (e) => {
        setNote({ ...note, [e.target.name]: e.target.value });
    };

    const handleNoteClose = () => setNoteShow(false);
    const handleNoteShow = () => setNoteShow(true);

    const geHorses = async () => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const getRequestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const horsesGet = await fetch(`${API_URL}/Horse/byid/${horsesEdit.id}`, getRequestOptions);
        const horsesResult = await horsesGet.json();
        console.log('horsesResult', horsesResult.successData);
        setEditHorses({
            ...editHorses,
            id: horsesResult.successData?.id,
            sireName: horsesResult.successData?.sireName,
            damName: horsesResult.successData?.damName,
            name: horsesResult.successData?.name,
            sex: horsesResult.successData?.sex,
            hipNumber: horsesResult.successData?.hipNumber,
            consignor: horsesResult.successData?.consignor,
            price: horsesResult.successData?.price,
        });
    }

    useEffect(() => {
        // geHorses();
    }, [])


    async function SubmitHandler(e) {
        e.preventDefault();

        if (note.note == '') {
            setNoteError(true);
        } else {
            setNoteError(false);

            setLoading(true);
            const auth = JSON.parse(localStorage.getItem("auth"));
            const userId = auth.userId;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${auth.token}`);

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    ...note,
                    createdBy: userId,
                }),
                headers: myHeaders,
                redirect: 'follow'
            };

            const create = await fetch(`${API_URL}/Note/create`, requestOptions);
            const result = await create.json();
            // console.log('result', result);

            if (result.isSuccess == true) {
                setNote({ ...note, note: "" });
                handleNoteClose();
                handlePagination(currentPage, pageSize);
                setLoading(false);
                // onClose();

                Swal.fire({
                    icon: 'success',
                    title: 'Note Has Been Added Successfully!',
                })

                const getRequestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                const horsesGet = await fetch(`${API_URL}/Horse/byid/${horsesEdit.id}`, getRequestOptions);
                const horsesResult = await horsesGet.json();
                console.log('horsesResult', horsesResult.successData);

                setHorsesEdit(horsesResult.successData);

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Note Has Not Been Added Successfully!',
                })
            }
            setLoading(false);
        }
    }


    const validate = (values) => {
        let errors = {};

        if (!values.hipNumber) {
            errors.hipNumber = "Hip Number Field Is Required!";
        }

        if (!values.name) {
            errors.name = "Name Field Is Required!";
        }

        // if (!values.price) {
        //     errors.price = "Price Field Is Required!";
        // }

        // if (!values.consignor) {
        //     errors.consignor = "Consignor Field Is Required!";
        // }

        return errors;
    };

    const handleClickOutside = (event) => {
        if (lenRef.current && !lenRef.current.contains(event.target)) {
            setSireShow(false);
            setDamShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);


    async function SubmitHorsesEdit(e) {
        e.preventDefault();
        setLoadingSave(true);

        // const newErroe = validate(horsesEdit);


        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify({
                id: editHorses.id,
                sireName: editHorses?.sireName,
                damName: editHorses?.damName,
                name: editHorses.name,
                sex: editHorses.sex,
                hipNumber: editHorses.hipNumber,
                consignor: editHorses.consignor,
                price: editHorses.price,
            }),
            headers: myHeaders,
            redirect: 'follow'
        };

        const create = await fetch(`${API_URL}/Horse/edit/${horsesEdit.id}`, requestOptions);
        const result = await create.json();
        console.log('result', result);

        if (result.isSuccess == true) {
            handlePagination(currentPage, pageSize);
            Swal.fire({
                icon: 'success',
                title: 'Horse Has Been Updated Successfully!',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    onClose();
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Horse Has Been Not Updated Successfully!',
            })
        }
        setLoadingSave(false);
    }

    const NoteDelHandler = async (id) => {
        Swal.fire({
            title: 'Are You Sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete It!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                const auth = JSON.parse(localStorage.getItem("auth"));
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${auth.token}`);

                const requestOptions = {
                    method: 'DELETE',
                    body: JSON.stringify({
                        ...note,
                    }),
                    headers: myHeaders,
                    redirect: 'follow'
                };

                const delNote = await fetch(`${API_URL}/Note/delete/${id}`, requestOptions);
                const DelResult = await delNote.json();
                console.log('DelResult', DelResult);

                if (DelResult.isSuccess == true) {
                    setNote({ ...note, note: "" });
                    handlePagination(currentPage, pageSize);
                    Swal.fire({
                        icon: 'success',
                        title: 'Note Has Been Deleted Successfully!',
                    })

                    const getRequestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    const horsesGet = await fetch(`${API_URL}/Horse/byid/${horsesEdit.id}`, getRequestOptions);
                    const horsesResult = await horsesGet.json();
                    console.log('horsesResult', horsesResult.successData);
                    setHorsesEdit(horsesResult.successData);
                    setEditHorses({
                        ...editHorses,
                        id: horsesResult.successData?.id,
                        sireName: horsesResult.successData?.sireName,
                        damName: horsesResult.successData?.damName,
                        name: horsesResult.successData?.name,
                        hipNumber: horsesResult.successData?.hipNumber,
                        consignor: horsesResult.successData?.consignor,
                        price: horsesResult.successData?.price,
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Note Has Not Been Deleting Successfully!',
                    })
                }
            }
        })

        setLoading(false);
        setLoadingSave(false);
    }

    return (
        <div className='modal__wrapper'>
            <div className='modal__card'>

                <div className='modal__head'>
                    <h3>Horse Information</h3>
                    <span onClick={onClose}><i className="fas fa-times"></i></span>
                </div>

                <div className='row mt-3 modal__form' ref={lenRef}>
                    <div className='col-md-6 mb-3'>
                        <div className='form-group text-start position-relative'>
                            <label className='mb-2'>Sire</label>
                            {
                                horsesEdit.isSireCpuSelected == null ?
                                    <input
                                        type="text"
                                        className="form-control form-select"
                                        value={editHorses.sireName}
                                        name="name"
                                        onChange={getSireList}
                                        onMouseDown={() => setSireShow(true)}
                                        autocomplete="off"
                                    /> : <>
                                        {
                                            horsesEdit.isSireCpuSelected == false ? <input
                                                type="text"
                                                className="form-control form-select"
                                                value={editHorses.sireName}
                                                name="name"
                                                onChange={getSireList}
                                                onMouseDown={() => setSireShow(true)}
                                                autocomplete="off"
                                            /> : <input
                                                type="text"
                                                className="form-control form-select"
                                                value={editHorses.sireName}
                                                autocomplete="off"
                                                disabled
                                            />
                                        }
                                    </>
                            }
                            <ul
                                className={`list-group position-absolute mt-1 w-100 shadow-lg bg-body rounded overflow-auto scrollbar ${sireShow ? `` : `d-none`}`}
                                style={{ maxHeight: '200px' }}
                            >

                                {
                                    isSireLoad ? <li className="list-group-item text-center">
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Searching.....
                                    </li> :
                                        <>
                                            {
                                                sireData == '' ? <>
                                                    <li className="list-group-item text-center">Type 3 characters to search</li>
                                                </> : <>
                                                    {sireData.map((item, index) => (
                                                        <li
                                                            className="list-group-item d-flex justify-content-between"
                                                            style={{ cursor: 'pointer', fontSize: '14px' }}
                                                            onClick={() => addSireInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                                                        >
                                                            <span className='text-start' style={{ width: '53%' }}>
                                                                {item.horseName.length > 14 ? `${item.horseName.substring(0, 14)}...` : item.horseName}
                                                            </span>
                                                            <span className='text-center' style={{ width: '4%' }}>|</span>
                                                            <span className='text-end' style={{ width: '45%' }}>
                                                                {moment(item.foalingDate).format(
                                                                    "MM/DD/YYYY"
                                                                )}
                                                            </span>
                                                        </li>
                                                    ))
                                                    }
                                                </>
                                            }
                                        </>
                                }
                            </ul>
                        </div>
                    </div>

                    <div className='col-md-6 mb-3' ref={lenRef}>
                        <div className='form-group text-start position-relative'>
                            <label className='mb-2'>Dam</label>
                            {
                                horsesEdit.isDamCpuSelected == null ?
                                    <input
                                        type="text"
                                        className="form-control form-select"
                                        value={editHorses.damName}
                                        name="name"
                                        onChange={getDamList}
                                        onMouseDown={() => setDamShow(true)}
                                        autocomplete="off"
                                    /> :
                                    <>
                                        {
                                            horsesEdit.isDamCpuSelected == false ? <input
                                                type="text"
                                                className="form-control form-select"
                                                value={editHorses.damName}
                                                name="name"
                                                onChange={getDamList}
                                                onMouseDown={() => setDamShow(true)}
                                                autocomplete="off"
                                            /> : <input
                                                type="text"
                                                className="form-control form-select"
                                                value={editHorses.damName}
                                                autocomplete="off"
                                                disabled
                                            />
                                        }
                                    </>
                            }
                            <ul
                                className={`list-group position-absolute mt-1 w-100 shadow-lg bg-body rounded overflow-auto scrollbar ${damShow ? `` : `d-none`}`}
                                style={{ maxHeight: '200px' }}
                            >
                                {
                                    isDamLoad ? <li className="list-group-item text-center">
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Searching.....
                                    </li> : <> {
                                        damData == '' ? <>
                                            <li className="list-group-item text-center">Type 3 characters to search</li>
                                        </> : <>
                                            {
                                                damData.map((item, index) => (
                                                    <li
                                                        className="list-group-item d-flex justify-content-between"
                                                        style={{ cursor: 'pointer', fontSize: '14px' }}
                                                        onClick={() => addDamInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                                                    >
                                                        <span className='text-start' style={{ width: '53%' }}>
                                                            {item.horseName.length > 14 ? `${item.horseName.substring(0, 14)}...` : item.horseName}
                                                        </span>
                                                        <span className='text-center' style={{ width: '4%' }}>|</span>
                                                        <span className='text-end' style={{ width: '45%' }}>
                                                            {moment(item.foalingDate).format(
                                                                "MM/DD/YYYY"
                                                            )}
                                                        </span>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    }
                                    </>
                                }
                            </ul>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Hip Number</label>
                            <input type="text" className='form-control'
                                name='hipNumber'
                                value={editHorses.hipNumber}
                                onChange={updateHorses}
                                placeholder='Hip Number' />
                        </div>
                        {/* {errors?.hipNumber?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.hipNumber}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 mt-2 text-start'>
                            <label className='mb-2'>* Sex</label>
                            <select className='form-control'
                                name='sex'
                                value={editHorses.sex}
                                onChange={updateHorses}
                            >
                                <option>Select</option>
                                <option value={'male'} selected={editHorses.sex == 'Male' ? true : false}>Male</option>
                                <option value={'female'} selected={editHorses.sex == 'Female' ? true : false}>Female</option>
                            </select>
                        </div>
                        {errors?.sex?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.sex}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Horse Name</label>
                            <input type="text" className='form-control'
                                name='name'
                                value={editHorses.name}
                                onChange={updateHorses}
                                placeholder='New Horse Name' />
                        </div>
                        {/* {errors?.name?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.name}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Price</label>
                            <input type="text" className='form-control'
                                name='price'
                                value={editHorses.price}
                                onChange={updateHorses}
                                placeholder='Price' />
                        </div>
                        {/* {errors?.price?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.price}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Consignor</label>
                            <input type="text" className='form-control'
                                name='consignor'
                                value={editHorses.consignor}
                                onChange={updateHorses}
                                placeholder='Consignor' />
                        </div>
                        {/* {errors?.consignor?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.consignor}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-12'>
                    </div>

                    <div className='col-md-12  text-start'>
                        <h4 className='label__2'>Notes</h4>
                    </div>


                    <div className='col-md-12 scrollbar overflow-auto' style={{ maxHeight: '300px' }}>
                        <div className='row w-100'>
                            {
                                horsesEdit.horseNotes == '' ? null : <>
                                    {
                                        horsesEdit.horseNotes.map((item, index) => (
                                            <div key={index} className='col-md-12  p-2 mt-2 mb-2'>
                                                <h4 className='label__1 w-100 d-flex justify-content-between'>
                                                    <span>{moment(item.createdAt).format("DD MMMM YYYY")}</span>
                                                    <i className="fas fa-trash text-danger" onClick={() => NoteDelHandler(item.id)} style={{ cursor: 'pointer' }}></i>
                                                </h4>
                                                <p className='pera__1 w-100 text-start'>{item.note}</p>
                                                <p className='w-100 mt-1 border-bottom'></p>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </div>

                    <div className='col-md-12 mt-3 mb-3 text-start'>
                        <textarea className='form-control textarea' placeholder='Write here' rows={3} name="note" value={note.note} onChange={updateNote} required disabled={horsesEdit?.id == '' ? true : false}></textarea>

                        {
                            noteErroer ? <div className="text-danger mt-2 text-start">
                                This field is required
                            </div> : null
                        }
                    </div>

                    <div className='col-md-12 mb-2 d-flex justify-content-start'>
                        {
                            loading ? <button className="btn btn-primary">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button> : <button
                                className='btn btn-primary site-s-font'
                                onClick={SubmitHandler}
                                disabled={horsesEdit?.id == '' ? true : false}
                            >
                                <i className="fas fa-plus-circle"></i> Add Note
                            </button>
                        }
                    </div>

                    <div className='modal__footer'>
                        <div className='col-md-12 d-flex justify-content-end gap-2'>
                            <button className='btn btn-defaut border site-s-font' onClick={onClose}>Cancel</button>
                            {
                                loadingSave ? <button className="btn btn-primary">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button> : <button
                                    className='btn btn-primary site-s-font'
                                    onClick={SubmitHorsesEdit}
                                    style={{
                                        // backgroundColor: 'rgba(84, 124, 255, 1)'
                                    }}
                                >Save</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal