/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Pagination } from 'antd';

import Swal from "sweetalert2";

import Layout from "./Layouts/Layout";
import ShareModal from "./modal/ShareModal";
import Modal from "./modal/Modal";
import NotesModal from "./modal/NotesModal";
import ViewRow from "./ViewRow";
import { baseUrl, API_URL } from "../config/config";

import ViewEventRow from "./ViewEventRow";
import ViewEventFavoriteRow from "./ViewEventFavoriteRow";
import { logout } from "../features/slices/authslice.";
import { AiFillFilter } from "react-icons/ai";
import { useDispatch } from "react-redux";
import SortIcon from "./icons/SortIcon";
import { MdClear } from "react-icons/md";
import TableLoading from "./TableLoading";

const EventDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabActive, setTabActive] = useState(1);
  const [showShare, setShowShare] = useState(false);
  const [viewRow, setViewRow] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [horses, setHorses] = useState([]);
  const [horsesEdit, setHorsesEdit] = useState([]);

  const [eventDetails, setEventDetails] = useState([]);
  const [favoriteDetails, setFavoriteDetails] = useState([]);
  const [highestStatus, setHighestStatus] = useState(false);
  const [sort, setSort] = useState(true);
  const [sortPage, setSortPage] = useState(true);
  const [breedStatus, setBreedStatus] = useState("get");
  const [gsrSort, setGsrSort] = useState("");

  const [shareID, setShareID] = useState("");
  const [highestSort, setHighestSort] = useState("");

  const [searchErr, setSearchErr] = useState(false);
  const [search, setSearch] = useState({
    name: "",
  });


  /* Notes*/
  const [noteShow, setNoteShow] = useState(false);
  /* Notes*/

  const [hipNumberErr, setHipNumberErr] = useState(false);
  const [hipFilter, setHipFilter] = useState({
    hipnumber: "",
  });

  const [hipNumbers, setHipNumbers] = useState([]);

  const updateSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const updateFilter = (e) => {
    setHipFilter({ ...hipFilter, [e.target.name]: e.target.value });
  };

  const getEventDetails = async (page, size) => {
    setLoading(true);
    setBreedStatus("get");
    setHighestSort("");

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const eventInfo = await fetch(
      `${API_URL}/Event/byid/${id}?orderBy=Id&orderByDesc=true&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await eventInfo.json();
    // console.log('result', result);
    // console.log('result', result);

    if (eventInfo.status === 401) {
      dispatch(logout());
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const getEventFavorite = async (page, size) => {
    setBreedStatus("favorite");
    setHighestSort("");

    setLoading(false);

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const eventInfo = await fetch(
      `${API_URL}/Event/byid/${id}?orderBy=Id&orderByDesc=true&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await eventInfo.json();
    // console.log('result', result);
    // console.log('result', result);

    if (eventInfo.status === 401) {
      dispatch(logout());
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }
  };

  const getEventSearch = async (e, page, size) => {
    e.preventDefault();
    setLoading(true);
    setHighestSort("");

    setBreedStatus("search");

    if (search.name == "") {
      // setSearchErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Sire or Dam Name.',
      // })
      getEventDetails(currentPage, pageSize);;
    } else {
      const auth = JSON.parse(localStorage.getItem("auth"));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const eventInfo = await fetch(
        `${API_URL}/Event/byid/${id}?searchBy=sireOrDamName&searchTerm=${search.name}&page=${page}&pageSize=${size}`,
        requestOptions
      );
      const result = await eventInfo.json();
      // console.log('result', result);

      if (eventInfo.status === 401) {
        dispatch(logout());
      }

      const favoriteList = [];

      if (result.isSuccess == false) {
        setFavoriteDetails([]);
      } else {
        result.successData.horses.result.forEach((item) => {
          if (item.isFavorite == true) {
            favoriteList.push(item);
          }
        });
      }

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    }
  };

  const getEventSearchPage = async (page, size) => {
    setLoading(false);

    setHighestSort("");
    setBreedStatus("search");

    if (search.name == "") {
      // setSearchErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Sire or Dam Name.',
      // })
      getEventDetails(currentPage, pageSize);;
    } else {
      const auth = JSON.parse(localStorage.getItem("auth"));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const eventInfo = await fetch(
        `${API_URL}/Event/byid/${id}?filters=HasSireName@=${search.name}&HasDamName@=${search.name}&page=${page}&pageSize=${size}`,
        requestOptions
      );
      const result = await eventInfo.json();
      // console.log('result', result);

      if (eventInfo.status === 401) {
        dispatch(logout());
      }

      const favoriteList = [];

      if (result.isSuccess == false) {
        setFavoriteDetails([]);
      } else {
        result.successData.horses.result.forEach((item) => {
          if (item.isFavorite == true) {
            favoriteList.push(item);
          }
        });
      }

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);

      setTimeout(() => {
        setLoading(false);
      }, 1200);
    }
  };




  const handleRemoveItem = async (del, page, size) => {

    setLoading(true);

    const addHips = hipNumbers.filter((item) => item.id !== del);

    let newHips = [];

    addHips.forEach((item) => {
      newHips.push(item.title);
    })

    setHipNumbers(addHips);

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const hips = newHips.join('|');

    const eventInfo = await fetch(`${API_URL}/event/byid/${id}?searchBy=hipNumber&searchTerm=${hips}&page=${page}&pageSize=${size}`, requestOptions);
    const result = await eventInfo.json();
    console.log("result", result);

    if (eventInfo.status === 401) {
      dispatch(logout());
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
      setFavoriteDetails([]);
      setEventDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });
      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }

  const getEventFilterHipNumber = async (e, page, size) => {
    e.preventDefault();
    setLoading(true);
    setHighestSort("");

    setBreedStatus("filterHipNumber");

    if (hipFilter.hipnumber == "") {
      // setHipNumberErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Hip Number.',
      // })

      getEventDetails(currentPage, pageSize);
    } else {
      setHipFilter({ ...hipFilter, hipnumber: "" });

      const hipNum = {
        id: hipNumbers.length + 1,
        title: hipFilter.hipnumber,
      };

      setHipNumbers((oldData) => [...oldData, hipNum]);

      const addHips = [...hipNumbers, hipNum];

      let newHips = [];

      addHips.forEach((item) => {
        newHips.push(item.title);
      })


      const auth = JSON.parse(localStorage.getItem("auth"));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const hips = newHips.join('|');
      console.log('hips', hips)

      const eventInfo = await fetch(`${API_URL}/Event/byid/${id}?searchBy=hipNumber&searchTerm=${hips}&page=${page}&pageSize=${size}`, requestOptions);
      const result = await eventInfo.json();
      console.log("result", result);

      if (eventInfo.status === 401) {
        dispatch(logout());
      }

      const favoriteList = [];

      if (result.isSuccess == false) {
        setFavoriteDetails([]);
        setFavoriteDetails([]);
        setEventDetails([]);
      } else {
        result.successData.horses.result.forEach((item) => {
          if (item.isFavorite == true) {
            favoriteList.push(item);
          }
        });
        setFavoriteDetails(favoriteList);
        setEventDetails(result.successData);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1200);
    }
  };

  const getEventFilterHipNumberPage = async (e, page, size) => {
    e.preventDefault();
    setLoading(true);

    setHighestSort("");
    setBreedStatus("filterHipNumber");

    if (hipFilter.hipnumber == "") {
      // setHipNumberErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Hip Number.',
      // })

      getEventDetails(currentPage, pageSize);;
    } else {
      const auth = JSON.parse(localStorage.getItem("auth"));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      let newHips = [];
      hipNumbers.forEach((item) => {
        newHips.push(item.title);
      })

      const eventInfo = await fetch(
        `${API_URL}/Event/byid/${id}?filters=HorseHipNumber==${newHips}&page=${page}&pageSize=${size}`,
        requestOptions
      );
      const result = await eventInfo.json();
      console.log("result", result);

      if (eventInfo.status === 401) {
        dispatch(logout());
      }

      const favoriteList = [];

      if (result.isSuccess == false) {
        setFavoriteDetails([]);
        setFavoriteDetails([]);
        setEventDetails([]);
      } else {
        result.successData.horses.result.forEach((item) => {
          if (item.isFavorite == true) {
            favoriteList.push(item);
          }
        });
        setFavoriteDetails(favoriteList);
        setEventDetails(result.successData);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    }
  };

  const getFiltersHighestLowest = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setSortPage(info);

    setHighestSort("Price");
    let newSort = highestStatus == true ? false : true;
    setHighestStatus(newSort);

    setBreedStatus("highestLowest");

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // orderBy=sireName|damName&orderByDesc=false|true
    /* 
      `${API_URL}/Event/byid/${id}?sorts=${info ? "-HorsePrice" : "HorsePrice"
      }&page=${page}&size=${size}`,

    */
    const eventInfo = await fetch(
      `${API_URL}/Event/byid/${id}?orderBy=price&orderByDesc=${info}&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await eventInfo.json();
    console.log("result", result);

    if (eventInfo.status === 401) {
      dispatch(logout());
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const getFiltersHighestLowestPage = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setSortPage(info);
    setHighestSort("Price");

    let newSort = highestStatus == true ? false : true;
    setHighestStatus(newSort);

    setBreedStatus("highestLowest");

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const eventInfo = await fetch(
      `${API_URL}/Event/byid/${id}?sorts=${info ? "-HorsePrice" : "HorsePrice"
      }&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await eventInfo.json();
    console.log("result", result);

    if (eventInfo.status === 401) {
      dispatch(logout());
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const getFiltersSort = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);
    setHighestSort("");
    setBreedStatus("filtersSort");

    let newSort = info == true ? false : true;
    setSort(newSort);

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const eventInfo = await fetch(
      `${API_URL}/Event/byid/${id}?orderBy=ID&orderByDesc=${newSort}&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await eventInfo.json();
    console.log("result", result);

    if (eventInfo.status === 401) {
      logout();
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const getFiltersGsrSort = async (e, page, size, info) => {
    e.preventDefault();
    setLoading(true);

    setHighestSort("");
    setSortPage(sort);
    setGsrSort(info);

    setBreedStatus("filtersGsrSort");

    let newSort;
    if (gsrSort == info) {
      newSort = sort == true ? false : true;
    } else {
      newSort = true;
    }

    setSort(newSort);

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let eventInfo;
    if (search.name == "") {
      eventInfo = await fetch(`${API_URL}/event/byid/${id}?orderBy=${info}&orderByDesc=${newSort}&page=${page}&pageSize=${size}`, requestOptions);
    } else {
      eventInfo = await fetch(`${API_URL}/Event/byid/${id}?orderBy=${info}&orderByDesc=${newSort}&searchBy=sireOrDamName&searchTerm=${search.name}&page=${currentPage}&pageSize=${size}`, requestOptions);
    }

    const result = await eventInfo.json();
    console.log("result", result);

    if (eventInfo.status === 401) {
      logout();
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  const getFiltersGsrSortPage = async (page, size, new_sort, info) => {

    setLoading(true);
    setHighestSort("");
    setSortPage(new_sort);
    setGsrSort(info);
    setBreedStatus("filtersGsrSort");

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const eventInfo = await fetch(
      `${API_URL}/Event/byid/${id}?orderBy=${info}&orderByDesc=${new_sort}&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await eventInfo.json();
    console.log("result", result);

    if (eventInfo.status === 401) {
      logout();
    }

    const favoriteList = [];

    if (result.isSuccess == false) {
      setFavoriteDetails([]);
    } else {
      result.successData.horses.result.forEach((item) => {
        if (item.isFavorite == true) {
          favoriteList.push(item);
        }
      });

      setFavoriteDetails(favoriteList);
      setEventDetails(result.successData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  useEffect(() => {
    getEventDetails(currentPage, pageSize);
  }, []);

  const ClearAll = () => {
    getEventDetails(currentPage, pageSize);
    setSearch({ ...search, name: "" });
  };

  const ClearAllHip = () => {
    setHipFilter({ ...hipFilter, hipnumber: "" });
    setHipNumbers([]);
  };


  const handlePagination = (page, size) => {
    setCurrentPage(page);
    setPageSize(size)

    if (breedStatus == "get") {
      getEventDetails(page, size);
    } else if (breedStatus == "search") {
      getEventSearchPage(page, size);
    } else if (breedStatus == "filterHipNumber") {
      getEventFilterHipNumberPage(event, page, size);
    } else if (breedStatus == "highestLowest") {
      getFiltersHighestLowestPage(event, page, size, highestStatus);
    } else if (breedStatus == "filtersSort") {
      getFiltersSortPage(event, page, size, sort);
    } else if (breedStatus == "filtersGsrSort") {
      getFiltersGsrSortPage(event, page, size, sortPage, gsrSort);
    } else if (breedStatus == "favorite") {
      getEventFavorite(page, size);
    }
  };

  const handleShare = async (id) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const searchInfo = await fetch(
      `${API_URL}/Event/createeventlink/${id}`,
      requestOptions
    );
    const result = await searchInfo.json();
    // console.log('handleShare result', result);
    setShareID(result.successData.token);
    setShowShare(true);
  };

  return (
    <>
      <Layout>
        <Modal
          show={show}
          onClose={() => setShow(false)}
          setShow={setShow}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handlePagination={handlePagination}
        />
        <NotesModal
          noteShow={noteShow}
          setNoteShow={setNoteShow}
          onClose={() => setNoteShow(false)}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handlePagination={handlePagination}
        />
        <ShareModal
          showShare={showShare}
          id={shareID}
          onClose={() => setShowShare(false)}
        />
        <div className="bg__area__content">
          <div className="container">
            <div className="py-2">
              <div className="page_breadcum">
                <ul>
                  <li>
                    {" "}
                    <Link to="/events">
                      <i className="fas fa-house"></i> Events /
                    </Link>{" "}
                  </li>
                  <li className="active">
                    {" "}
                    <Link to={`/event/${id}`}>Event Details </Link>{" "}
                  </li>
                </ul>
              </div>

              <div className="table__header shadow-sm ">
                <div className="d-flex justify-content-between align-items-center">
                  <h3>{eventDetails?.name}</h3>
                  <button
                    className="btn btn-default border text-primary"
                    onClick={() => handleShare(id)}
                  >
                    <i className="fas fa-share-alt"></i> Share
                  </button>
                </div>

                <div className="table__Tabs">
                  <div
                    className={`sigle-tab ${tabActive == 1 ? "active" : ""}`}
                    onClick={() => setTabActive(1)}
                  >
                    All Horses
                  </div>
                  <div
                    className={`sigle-tab ${tabActive == 2 ? "active" : ""}`}
                    onClick={() => setTabActive(2)}
                  >
                    Favorite Horses
                  </div>
                </div>
                <div className="d-flex justify-content-between table__header__row">
                  <div className="d-flex align-items-center gap-2 second-search__box">
                    <form
                      className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                      onSubmit={(e) => getEventSearch(e, 1, pageSize)}
                    >
                      <div className="table_search_input input-group form-group position-relative">
                        <input
                          type="text"
                          className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""}`}
                          name="name"
                          value={search.name}
                          onChange={updateSearch}
                          placeholder="Search by sire or dam name"
                        />
                        <span
                          className="input-group-append position-absolute"
                          style={{
                            zIndex: "10",
                            marginTop: "-2px",
                            right: "-7px",
                          }}
                        >
                          {search.name.length > 0 ? (
                            <div className="input-group-text border-0 bg-transparent ml-n5">
                              <span
                                className=""
                                style={{
                                  cursor: "pointer",
                                  width: "30px",
                                  height: "30px",
                                }}
                                onClick={ClearAll}
                              >
                                <MdClear className="clear_logo" />
                              </span>
                            </div>
                          ) : null}
                        </span>
                      </div>
                      <button
                        className="btn btn-primary"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "22px",
                          marginRight: "5px",
                        }}
                      >
                        Search
                      </button>
                    </form>

                    <form
                      className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                      onSubmit={(e) => getEventFilterHipNumber(e, 1, pageSize)}
                    >
                      <div className="table_search_input input-group form-group position-relative">
                        <input
                          type="text"
                          className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""
                            }`}
                          name="hipnumber"
                          value={hipFilter.hipnumber}
                          onChange={updateFilter}
                          placeholder="Filter by hip number"
                        />
                        <span
                          className="input-group-append position-absolute"
                          style={{
                            zIndex: "10",
                            marginTop: "-2px",
                            right: "-7px",
                          }}
                        >
                          {hipFilter.hipnumber.length > 0 ? (
                            <div className="input-group-text border-0 bg-transparent ml-n5">
                              <span
                                className=""
                                style={{
                                  cursor: "pointer",
                                  width: "30px",
                                  height: "30px",
                                }}
                                onClick={ClearAllHip}
                              >
                                <MdClear className="clear_logo" />
                              </span>
                            </div>
                          ) : null}
                        </span>
                      </div>
                      <button className="btn btn-primary">
                        {/* <i className="fas fa-filter" style={{ fontSize: 12 }}></i>  */}
                        <AiFillFilter
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            marginRight: "5px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            marginRight: "5px",
                          }}
                        >
                          Filter
                        </span>
                      </button>
                    </form>
                  </div>
                  <div className="d-flex">
                    <div
                      className="dropdown"
                      style={{ width: "160px!important" }}
                    >
                      <button
                        className="btn btn-default mx-2 border d-flex align-items-center justify-content-center text-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        style={{
                          width: "170px",
                          height: "39px",
                        }}
                      >
                        <AiFillFilter
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            marginRight: "5px",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "22px",
                          }}
                        >
                          Sort by Price
                        </span>
                      </button>
                      <ul
                        class="dropdown-menu border-start border-end border-bottom border-top-0"
                        style={{ width: "170px !important" }}
                      >
                        <li
                          onClick={(e) => getFiltersHighestLowest(e, 1, 10, true)}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "22px",
                            }}
                          >
                            Highest to Lowest
                          </a>
                        </li>
                        <li
                          onClick={(e) => getFiltersHighestLowest(e, 1, 10, false)}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "22px",
                            }}
                          >
                            Lowest to Highest
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {
                  hipNumbers == "" ? null : <div className="d-flex justify-content-start gap-2 table__header__row">
                    {
                      hipNumbers.map((item, index) => (
                        <span key={index} class="btn btn-light btn-sm border  d-flex align-content-center" style={{ height: "32px", display: "flex", itemsAlign: "center" }}>
                          <span style={{ marginTop: "0px", fontSize: "14px", fontWeight: "400" }}>{item.title}</span>
                          <MdClear
                            onClick={() => handleRemoveItem(item.id, 1, 10)}
                            className="clear_btn"
                            style={{ cursor: "pointer", marginLeft: '10px', marginTop: "4px", fontSize: "14px", fontWeight: "400" }}
                          />
                        </span>
                      ))
                    }
                    <button onClick={ClearAllHip} class="btn btn-pink-moon btn-sm">Clear All</button>
                  </div>
                }



                {tabActive == 1 ? (
                  <div className="table-responsive table__fix_head mt-1">
                    <table className="table mt-1">
                      <tr className="table__head__row">
                        <th
                          className="table__tdp"
                          style={{ width: "48px" }}
                        ></th>
                        <th
                          className="table__tdp"
                          style={{ width: "60px", paddingLeft: "0px" }}
                        >
                          ID
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "147.57px", paddingLeft: "0px" }}
                        >
                          Sire Name
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "147.57px", paddingLeft: "0px" }}
                        >
                          Dam Name
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "147.57px", paddingLeft: "0px" }}
                        >
                          New Horse
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "60px", paddingLeft: "0px" }}
                        >
                          Sex
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "80px", paddingLeft: "0px" }}
                        >
                          Hip
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrDirt")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Dirt
                            {gsrSort == "gsrDirt" ? (
                              <SortIcon
                                select={
                                  gsrSort == "gsrDirt" ? true : false
                                }
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrTurf")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Turf
                            {gsrSort == "gsrTurf" ? (
                              <SortIcon
                                select={
                                  gsrSort == "gsrTurf" ? true : false
                                }
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrSynth")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Synth
                            {gsrSort == "gsrSynth" ? (
                              <SortIcon
                                select={
                                  gsrSort == "gsrSynth" ? true : false
                                }
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrWet")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Wet
                            {gsrSort == "gsrWet" ? (
                              <SortIcon
                                select={gsrSort == "gsrWet" ? true : false}
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{
                            width: "100px !important",
                            paddingLeft: "0px",
                          }}
                          onClick={(e) => {
                            highestStatus
                              ? getFiltersHighestLowest(e, 1, 10, false)
                              : getFiltersHighestLowest(e, 1, 10, true);
                          }}
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Price
                            {highestSort == "Price" ? (
                              <SortIcon
                                select={highestSort == "Price" ? true : false}
                                sort={highestStatus}
                              />
                            ) : (
                              <SortIcon select={null} sort={highestStatus} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "70px", paddingLeft: "0px" }}
                        >
                          Notes
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "213px", paddingLeft: "0px" }}
                        >
                          Action
                        </th>
                      </tr>
                      {loading ? (
                        <tr className="table__row table__row_content table-loading">
                          <td className="text-center" colSpan={14}>
                            <TableLoading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {eventDetails.horses.result == "" ? (
                            <tr className="table__row table__row_content">
                              <td className="text-center" colSpan={14}>
                                <div
                                  className="w-100 d-flex justify-content-center"
                                  style={{ height: "55vh" }}
                                >
                                  <img
                                    src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                    alt=""
                                    style={{ width: "150px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {eventDetails.horses.result.map((item, index) => (
                                <ViewEventRow
                                  key={index}
                                  item={item}
                                  setShow={setShow}
                                  noteShow={noteShow}
                                  setNoteShow={setNoteShow}
                                  horses={horses}
                                  setHorses={setHorses}
                                  horsesEdit={horsesEdit}
                                  setHorsesEdit={setHorsesEdit}
                                  getEventDetails={getEventDetails}
                                  handlePagination={handlePagination}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  breedStatus={breedStatus}
                                  setBreedStatus={setBreedStatus}
                                  getEventFavorite={getEventFavorite}
                                  getEventSearchPage={getEventSearchPage}
                                />
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </table>
                  </div>
                ) : (
                  <div className="table-responsive table__fix_head mt-1">
                    <table className="table mt-1">
                      <tr className="table__head__row">
                        <th
                          className="table__tdp"
                          style={{ width: "48px" }}
                        ></th>
                        <th
                          className="table__tdp"
                          style={{ width: "60px", paddingLeft: "0px" }}
                        >
                          ID
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "178px", paddingLeft: "0px" }}
                        >
                          Sire Name
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "178px", paddingLeft: "0px" }}
                        >
                          Dam Name
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "161px", paddingLeft: "0px" }}
                        >
                          New Horse
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "60px", paddingLeft: "0px" }}
                        >
                          Sex
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "80px", paddingLeft: "0px" }}
                        >
                          Hip
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrDirt")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Dirt
                            {gsrSort == "gsrDirt" ? (
                              <SortIcon
                                select={
                                  gsrSort == "gsrDirt" ? false : true
                                }
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrTurf")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Turf
                            {gsrSort == "gsrTurf" ? (
                              <SortIcon
                                select={
                                  gsrSort == "gsrTurf" ? false : true
                                }
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrSynth")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Synth
                            {gsrSort == "gsrSynth" ? (
                              <SortIcon
                                select={
                                  gsrSort == "gsrSynth" ? false : true
                                }
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "90px !important", paddingLeft: "0px" }}
                          onClick={(e) =>
                            getFiltersGsrSort(e, 1, 10, "gsrWet")
                          }
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Wet
                            {gsrSort == "gsrWet" ? (
                              <SortIcon
                                select={gsrSort == "gsrWet" ? false : true}
                                sort={sort}
                              />
                            ) : (
                              <SortIcon select={null} sort={sort} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{
                            width: "100px !important",
                            paddingLeft: "0px",
                          }}
                          onClick={(e) => {
                            highestStatus
                              ? getFiltersHighestLowest(e, 1, 10, false)
                              : getFiltersHighestLowest(e, 1, 10, true);
                          }}
                        >
                          <span
                            className="d-flex align-items-center justify-content-start"
                            style={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#FFF0",
                              marginLeft: "-7px",
                            }}
                          >
                            Price
                            {highestSort == "Price" ? (
                              <SortIcon
                                select={highestSort == "Price" ? true : false}
                                sort={highestStatus}
                              />
                            ) : (
                              <SortIcon select={null} sort={highestStatus} />
                            )}
                          </span>
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "70px", paddingLeft: "0px" }}
                        >
                          Notes
                        </th>
                        <th
                          className="table__tdp"
                          style={{ width: "213px", paddingLeft: "0px" }}
                        >
                          Action
                        </th>
                      </tr>
                      {loading ? (
                        <tr className="table__row table__row_content table-loading">
                          <td className="text-center" colSpan={14}>
                            <TableLoading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {favoriteDetails == "" ? (
                            <tr className="table__row table__row_content">
                              <td className="text-center" colSpan={14}>
                                <div
                                  className="w-100 d-flex justify-content-center"
                                  style={{ height: "55vh" }}
                                >
                                  <img
                                    src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                    alt=""
                                    style={{ width: "150px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {favoriteDetails.map((item, index) => (
                                <ViewEventFavoriteRow
                                  key={index}
                                  item={item}
                                  setShow={setShow}
                                  noteShow={noteShow}
                                  setNoteShow={setNoteShow}
                                  horses={horses}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  setHorses={setHorses}
                                  horsesEdit={horsesEdit}
                                  setHorsesEdit={setHorsesEdit}
                                  getEventDetails={getEventDetails}
                                  handlePagination={handlePagination}
                                  breedStatus={breedStatus}
                                  setBreedStatus={setBreedStatus}
                                  getEventFavorite={getEventFavorite}
                                  getEventSearchPage={getEventSearchPage}
                                />
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </table>
                  </div>
                )}
                {/* Tab - 1 */}

                {loading ? null : (
                  <>
                    {eventDetails.horses.result == "" ? null : (
                      <div className="table__pagination mt-3">
                        <Pagination
                          total={totalItems}
                          onChange={(page, pageSize) => handlePagination(page, pageSize)}
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EventDetails;
