/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Layout from "./Layouts/Layout";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Pagination } from 'antd';

import EventModal from "./modal/EventModal";
import PromtModal from "./modal/PromotModal";
import MetaModal from "./modal/MetaModal";
import AddData from "./modal/AddData";

import { parseCookies, setCookie, destroyCookie } from "nookies";
import cookie from "js-cookie";

import { useDispatch } from "react-redux";

import { useGetEventsQuery } from "../features/api/eventApi";
import { useCreateEventMutation } from "../features/api/eventApi";
import { ToastContainer, toast } from "react-toastify";
import ShareModal from "./modal/ShareModal";
import { baseUrl, API_URL } from "../config/config";
import { logout } from "../features/slices/authslice.";
import { MdClear } from "react-icons/md";
import TableLoading from "./TableLoading";

const AddToEvent = () => {
  const cookies = parseCookies();
  const getCookies = cookies.horses;

  const dispatch = useDispatch();

  const [horses, setHorses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingaAdd, setLoadingAdd] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(false);
  const [viewEvent, setViewEvent] = useState([]);

  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddMeta, setShowAddMeta] = useState(false);
  const [isMeta, setIsMeta] = useState(0);
  const [isAddData, setIsAddData] = useState(0);
  const [addshow, setAddShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [horsesEdit, setHorsesEdit] = useState({
    id: "",
    sireName: "",
    damName: "",
    hipNumber: "",
    sex: "",
    name: "",
    price: "",
    consignor: "",
    horseId: "",
  });

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [eventList, setEventList] = useState([]);

  const [eventInfo, setEventInfo] = useState({
    id: "",
    name: "",
  });
  const [eventName, setEventName] = useState("");

  const [search, setSearch] = useState({
    name: "",
  });

  const updateSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  useEffect(() => {

    // console.log('horses', horses);

    if (isMeta) {
      setShowAdd(false);
      setShowAddMeta(true);
    }

    if (isAddData) {
      setShowAddMeta(false);
      setAddShow(true);
      setHorsesEdit({
        ...horsesEdit,
        id: horses[0].id,
        sireName: horses[0].sireName,
        damName: horses[0].damName,
        hipNumber: horses[0].hipNumber,
        sex: horses[0].sex,
        name: horses[0].name,
        price: horses[0].price,
        consignor: horses[0].consignor,
        horseId: horses[0].horseId,
      });
      setIsAddData(0);
    }
  }, [isMeta, isAddData]);

  const getBreedHorses = async (page, size) => {
    setLoading(true);
    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const EventList = await fetch(
      `${API_URL}/Event/index?orderBy=Id&orderByDesc=true&page=${page}&size=${size}`,
      requestOptions
    );
    const result = await EventList.json();

    if (EventList.status === 401) {
      dispatch(logout());
    }

    // setCurrentPage(result.successData.currentPage);
    setCurrentPage(page);
    setTotalPages(result.successData.totalPages);
    setTotalItems(result.successData.totalItems);
    setEventList(result.successData.result);
    setLoading(false);

    // console.log('result', result);
    // console.log('successData', result.successData.result);
  };

  const getEventSearch = async (e) => {
    e.preventDefault();
    setLoading(true);

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // ?filters=name@=test&page=1&pageSize=10

    const searchInfo = await fetch(
      `${API_URL}/Event/index?searchBy=name&searchTerm=${search.name}&page=${currentPage}&pageSize=${pageSize}`,
      requestOptions
    );
    const result = await searchInfo.json();
    console.log("searchInfo result", result);

    if (searchInfo.status === 401) {
      dispatch(logout());
    }

    setCurrentPage(currentPage);

    if (result.successData == "") {
      setTotalPages(0);
      setTotalItems(0);
      setEventList([]);
    } else {
      setTotalPages(result.successData.totalPages);
      setCurrentPage(result.successData.currentPage);
      setTotalItems(result.successData.totalItems);
      setEventList(result.successData.result);
    }

    setLoading(false);
  };

  useEffect(() => {
    getBreedHorses(currentPage, pageSize);
    setHorses(JSON.parse(localStorage.getItem("horses")));
  }, []);

  const ClearAll = () => {
    getBreedHorses(currentPage, pageSize);
    setSearch({ ...search, name: "" });
  };

  const handlePagination = (page, size) => {
    getBreedHorses(page, size);
  };

  const handleShowAdd = (id, name, check) => {
    setHorses(horses);
    if (horses == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horses!",
      });
    } else {
      setEventInfo({
        ...eventInfo,
        id: id,
        name: name,
      });
      setShowAdd(check);
    }
  };

  const handleAddEvent = async (id, name) => {
    setShowAdd(false);
    setLoadingAdd(true);
    setIsMeta(0);
    let newHorses = [];

    horses.forEach((element) => {
      newHorses.push(element.horseId);
    });

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    var raw = JSON.stringify({
      id: id,
      name: name,
      horseIds: newHorses,
      addHorses: true,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const addEvent = await fetch(`${API_URL}/Event/edit/${id}`, requestOptions);
    const result = await addEvent.json();

    if (addEvent.status === 401) {
      dispatch(logout());
    }

    console.log("result", result);

    if (result.isSuccess == true) {
      setShowAddMeta(true);
      setShowAdd(false);
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Successfully Added Event!',
      // })
      // localStorage.setItem("horses", JSON.stringify([]));
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to Add Event!",
      });
    }

    setLoadingAdd(false);
    setViewEvent((old_ids) => [...old_ids, id]);
  };

  const handleShowAddMeta = (info) => {
    if (info == false) {
      localStorage.setItem("horses", JSON.stringify([]));
      setShowAddMeta(info);
    }
  };

  const handleCloseAdd = (info) => {
    if (info == false) {
      setAddShow(info);
      setIsMeta(0);
      setIsAddData(0);
      localStorage.setItem("horses", JSON.stringify([]));
    }
  };

  const saveEvent = async () => {
    setIsGetLoading(true);

    setSearch({ ...search, name: "" });

    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    var raw = JSON.stringify({
      name: eventName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const addEvent = await fetch(`${API_URL}/Event/create`, requestOptions);
    const result = await addEvent.json();

    if (addEvent.status === 401) {
      dispatch(logout());
    }

    console.log("result", result);

    if (result.isSuccess == true) {
      setShow(false);
      getBreedHorses(currentPage, pageSize);
      setEventName("");
      Swal.fire({
        icon: "success",
        title: "Event Has Been Created Successfully",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to Created Event!",
      });
    }

    setIsGetLoading(false);
  };

  return (
    <>
      <Layout>
        <EventModal
          isGetLoading={isGetLoading}
          eventName={eventName}
          saveEvent={saveEvent}
          setEventName={setEventName}
          show={show}
          onClose={() => setShow(false)}
        />
        <PromtModal
          showAdd={showAdd}
          onClose={() => {
            setShowAdd(false);
            setIsMeta(0);
            setIsAddData(0);
          }}
          setIsMeta={setIsMeta}
          handleAddEvent={handleAddEvent}
          horses={horses}
          eventInfo={eventInfo}
        />
        <MetaModal
          showAddMeta={showAddMeta}
          onCloseMeta={() => setShowAddMeta(false)}
          handleShowAddMeta={handleShowAddMeta}
          setIsAddData={setIsAddData}
          horses={horses}
          eventInfo={eventInfo}
        />
        <AddData
          addshow={addshow}
          horses={horses}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
          handleCloseAdd={handleCloseAdd}
        />
        <ShareModal showShare={showShare} onClose={() => setShowShare(false)} />

        <div className="bg__area__content">
          <div className="container">
            <div className="py-3">
              <div className="page_breadcum">
                <ul>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    <Link to="/breed-horses">
                      <i className="fas fa-home"></i> Breding /
                    </Link>{" "}
                  </li>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    <Link to="/bred-horses">Bred Horses /</Link>{" "}
                  </li>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    className="active"
                  >
                    {" "}
                    <Link to="/add-to-event">Add to Event</Link>{" "}
                  </li>
                </ul>
              </div>

              <div className="table__header">
                <h3
                  className="py-2"
                  style={{
                    fontSize: "36px",
                  }}
                >
                  Event List
                </h3>

                <div className="d-flex justify-content-between table__header__row">
                  <form
                    className="d-flex align-items-center mb-2 table__left_searchbtn gap-1"
                    onSubmit={getEventSearch}
                  >
                    <div className="table_search_input input-group form-group position-relative">
                      <input
                        type="text"
                        className="table_search_input form-control rounded input__field flex-md-grow-3"
                        name="name"
                        value={search.name}
                        onChange={updateSearch}
                        placeholder="Search by event name"
                      />
                      <span
                        className="input-group-append position-absolute"
                        style={{
                          zIndex: "10",
                          marginTop: "-2px",
                          right: "-7px",
                        }}
                      >
                        {search.name.length > 0 ? (
                          <div className="input-group-text border-0 bg-transparent ml-n5">
                            <span
                              className=""
                              style={{
                                cursor: "pointer",
                                width: "30px",
                                height: "30px",
                              }}
                              onClick={ClearAll}
                            >
                              <MdClear className="clear_logo" />
                            </span>
                          </div>
                        ) : null}
                      </span>
                    </div>
                    <button
                      className="btn btn-primary"
                      style={{ width: "80px", height: "40px" }}
                    >
                      Search
                    </button>
                  </form>
                  <button
                    className="btn btn-primary site-s-font"
                    onClick={() => setShow(true)}
                    style={{ width: "170px", height: "40px" }}
                  >
                    Create New Event
                  </button>
                </div>

                <div className="table-responsive table__fix_head">
                  <table className="table mt-1">
                    <tr className="table__head__row">
                      <th className="table__tdp" style={{ width: "84%" }}>
                        Event Name
                      </th>
                      <th className="table__tdp" style={{ width: "16%" }}>
                        Action
                      </th>
                    </tr>
                    <tbody>
                      {loading ? (
                        <tr className="table__row table__row_content table-loading">
                          <td className="text-center" colSpan={2}>
                            <TableLoading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {eventList == "" ? (
                            <tr className="table__row table__row_content">
                              <td className="text-center" colSpan={2}>
                                <div
                                  className="w-100 d-flex justify-content-center"
                                  style={{ height: "55vh" }}
                                >
                                  <img
                                    src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                    alt=""
                                    style={{ width: "150px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {eventList.map((item, index) => (
                                <tr
                                  key={item?.id}
                                  className="table__row table__row_content"
                                >
                                  <td className="p-2">
                                    <span className=" d-flex justify-content-between">
                                      <span>{item?.name}</span>
                                      {viewEvent.find((id) => id == item?.id) ? (
                                        <span className="text-success">
                                          {horses.length == 1
                                            ? "New Bred Horse Has Been Added To This Event"
                                            : "New Bred Horses Have Been Added To This Event"}
                                        </span>
                                      ) : null}
                                    </span>
                                  </td>
                                  <td className="p-2">
                                    {viewEvent.find((element) => element == item?.id) ? (
                                      <Link
                                        className="btn-simple"
                                        to={`/event/${item?.id}`}
                                        style={{
                                          width: "180px",
                                          height: "50px",
                                          padding: '5px',
                                        }}
                                      >
                                        View Event
                                      </Link>
                                    ) : (
                                      <button
                                        className="btn-simple"
                                        // onClick={() => handleAddEvent(item?.id,item?.name)}
                                        onClick={() =>
                                          handleShowAdd(
                                            item?.id,
                                            item?.name,
                                            true
                                          )
                                        }
                                        disabled={loadingaAdd}
                                        style={{
                                          width: "160px",
                                          height: "40px",
                                        }}
                                      >
                                        Add This To An Event
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                {loading ? null : (
                  <>
                    {eventList == "" ? null : (
                      <div className="table__pagination mt-3">
                        <Pagination
                          total={totalItems}
                          onChange={(page, pageSize) => handlePagination(page, pageSize)}
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddToEvent;
