/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react'
import moment from "moment";
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { parseCookies, setCookie, destroyCookie } from "nookies";
import cookie from "js-cookie";
import { baseUrl, API_URL } from '../../config/config';
import { logout } from '../../features/slices/authslice.';

const AddData = ({ addshow, handleCloseAdd, horses, horsesEdit, setHorsesEdit }) => {
    if (!addshow) return;


    const lenRef = useRef(null);

    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    console.log('horses', horses)
    console.log('length', horses.length)
    console.log('horsesEdit', horsesEdit)

    const [isSireLoad, setIsSireLoad] = useState(true);
    const [sireShow, setSireShow] = useState(false)
    const [sireData, setSireData] = useState([])
    const [sireInput, setSireInput] = useState({
        regID: "",
        name: "",
        date: "",
    })

    const [isDamLoad, setIsDamLoad] = useState(true)
    const [damShow, setDamShow] = useState(false)
    const [damData, setDamData] = useState([])
    const [damInput, setDamInput] = useState({
        regID: "",
        name: "",
        date: "",
    })


    const [errors, setErrors] = useState({
        sireName: "",
        damName: "",
        hipNumber: "",
        sex: "",
        name: "",
        price: "",
        consignor: "",
    });

    const updateHorses = (e) => {
        setHorsesEdit({ ...horsesEdit, [e.target.name]: e.target.value });
    };


    const getSireList = async (e) => {
        setIsSireLoad(true);
        setSireInput({ ...sireInput, [e.target.name]: e.target.value });
        setHorsesEdit({ ...horsesEdit, sireName: e.target.value });

        setSireShow(true);
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const getBreedHorses = await fetch(`${API_URL}/BreedHorses/SearchByHorseName?horseName=${e.target.value}&isSire=true`, requestOptions);
        const result = await getBreedHorses.json();

        if (getBreedHorses.status === 401) {
            dispatch(logout());
        }

        setSireData(result.successData);
        setIsSireLoad(false);

        // console.log('result', result.successData);
    };

    const addSireInfo = (regID, name, date,) => {
        setSireShow(false)
        setHorsesEdit({ ...horsesEdit, sireName: name });
    };

    const getDamList = async (e) => {
        setIsDamLoad(true)
        setDamInput({ ...damInput, [e.target.name]: e.target.value });
        setHorsesEdit({ ...horsesEdit, damName: e.target.value });

        setDamShow(true);
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const getBreedHorses = await fetch(`${API_URL}/BreedHorses/SearchByHorseName?horseName=${e.target.value}&isSire=false`, requestOptions);
        const result = await getBreedHorses.json();

        if (getBreedHorses.status === 401) {
            dispatch(logout());
        }

        setDamData(result.successData);
        setIsDamLoad(false)
    };

    const addDamInfo = (regID, name, date,) => {
        setDamShow(false);
        setHorsesEdit({ ...horsesEdit, damName: name });
    };

    const handleClickOutside = (event) => {
        if (lenRef.current && !lenRef.current.contains(event.target)) {
            setSireShow(false);
            setDamShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);
    
    const showSave = (length) => {
        if (length == 1) {
            return (<span
                onClick={() => SubmitHorsesEdit("", "", "", "", "", "", "", "", "", false, "", 1, true)}
                className='btn btn-primary site-s-font'>Save</span>);
        } else if (1 < length) {
            for (var i = 0; i < length; i++) {
                let x = i + 1;
                if (x < length) {
                    if (step == x) {
                        return (<span
                            onClick={() => {
                                SubmitHorsesEdit(
                                    horses[x].id,
                                    horses[x].sireName,
                                    horses[x].damName,
                                    `${horses[x].hipNumber == null ? '' : horses[x].hipNumber}`,
                                    horses[x].sex,
                                    horses[x].name,
                                    horses[x].price,
                                    `${horses[x].consignor == null ? '' : horses[x].consignor}`,
                                    horses[x].horseId,
                                    true,
                                    step + 1,
                                    length,
                                    false
                                );
                            }}
                            className='btn btn-primary site-s-font'>Save & Next</span>);
                    }
                } else if (step == length) {
                    return (<span
                        onClick={() => SubmitHorsesEdit("", "", "", "", "", "", "", "", "", false, "", length, true)}
                        className='btn btn-primary site-s-font'>Save</span>);
                }
            }
        }
    }

    const validate = (values) => {

        let errors = {};

        if (!values.sireName) {
            errors.sireName = "Sire Name Field Is Required!";
        }

        if (!values.damName) {
            errors.damName = "Dam Name Field Is Required!";
        }

        if (!values.hipNumber) {
            errors.hipNumber = "Hip Number Field Is Required!";
        }

        // if (!values.sex) {
        //     errors.sex = "Sex Field Is Required!";
        // }

        if (!values.name) {
            errors.name = "Name Field Is Required!";
        }

        // if (!values.price) {
        //     errors.price = "Price Field Is Required!";
        // }

        // if (!values.consignor) {
        //     errors.consignor = "Consignor Field Is Required!";
        // }

        return errors;
    };

    async function SubmitHorsesEdit(id, sireName, damName, hipNumber, sex, name, price, consignor, horseId, info, step, length, message) {
        // e.preventDefault();
        setLoadingSave(true);

        const newErroe = validate(horsesEdit);

        if (Object.keys(newErroe).length == 0) {
            setErrors({
                ...errors,
                sireName: "",
                damName: "",
                hipNumber: "",
                sex: "",
                name: "",
                price: "",
                consignor: "",
            });

            const auth = JSON.parse(localStorage.getItem("auth"));
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${auth.token}`);

            const requestOptions = {
                method: 'PUT',
                body: JSON.stringify({
                    id: horsesEdit.id,
                    sireName: horsesEdit.sireName,
                    damName: horsesEdit.damName,
                    name: horsesEdit.name,
                    hipNumber: horsesEdit.hipNumber,
                    sex: horsesEdit.sex,
                    consignor: horsesEdit.consignor,
                    price: horsesEdit.price,
                }),
                headers: myHeaders,
                redirect: 'follow'
            };

            const create = await fetch(`${API_URL}/Horse/edit/${horsesEdit.id}`, requestOptions);
            const result = await create.json();
            // console.log('result', result);

            if (result.isSuccess == true) {
                setHorsesEdit({
                    ...horsesEdit,
                    id: id,
                    sireName: sireName,
                    damName: damName,
                    hipNumber: hipNumber,
                    sex: sex,
                    name: name,
                    price: price,
                    consignor: consignor,
                    horseId: horseId,
                });

                setStep(step);
                if (message == true) {
                    Swal.fire({
                        icon: 'success',
                        title: `${length == 1 ? "Horse" : "Horses"} ${length == 1 ? "Has" : "Have"} Been Updated Successfully!`,
                    })
                }
                handleCloseAdd(info);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Horse Has Not Been Updated Successfully!',
                })
                handleCloseAdd(false);
            }
            setLoadingSave(false);

            if (horses.length == 1) {
                localStorage.setItem("horses", JSON.stringify([]));
            } else {
                for (var i = 0; i < horses.length; i++) {
                    if (i == horses.length) {
                        localStorage.setItem("horses", JSON.stringify([]));
                    }
                }
            }
        } else {
            setErrors({
                ...errors,
                sireName: newErroe?.sireName,
                damName: newErroe?.damName,
                hipNumber: newErroe?.hipNumber,
                sex: newErroe?.sex,
                name: newErroe?.name,
                price: newErroe?.price,
                consignor: newErroe?.consignor,
            });

            setLoadingSave(false);
        }

    }

    return (
        <div className='modal__wrapper'>
            <div className='modal__card'>

                <div className='modal__head text-start'>
                    <h3>Meta Data Required   ({step}/{horses.length})</h3>
                    <span onClick={() => handleCloseAdd(false)}><i className="fas fa-times"></i></span>
                </div>

                <div className='row mt-3 modal__form text-start' ref={lenRef}>
                    <div className='col-md-6'>
                        <div className='form-group text-start position-relative'>
                            <label className='mb-2'>* Sire Name</label>
                            <input
                                type="text"
                                className="form-control form-select"
                                value={horsesEdit.sireName}
                                name="name"
                                onChange={getSireList}
                                onMouseDown={() => setSireShow(true)}
                                autocomplete="off"
                            />
                            <ul
                                className={`list-group position-absolute mt-1 w-100 shadow-lg bg-body rounded overflow-auto scrollbar ${sireShow ? `` : `d-none`}`}
                                style={{ maxHeight: '200px' }}
                            >

                                {
                                    isSireLoad ? <li className="list-group-item text-center">
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Searching.....
                                    </li> :
                                        <>
                                            {
                                                sireData == '' ? <>
                                                    <li className="list-group-item text-center">Type 3 characters to search</li>
                                                </> : <>
                                                    {sireData.map((item, index) => (
                                                        <li
                                                            className="list-group-item d-flex justify-content-between"
                                                            style={{ cursor: 'pointer', fontSize: '14px' }}
                                                            onClick={() => addSireInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                                                        >
                                                            <span className='text-start' style={{ width: '53%' }}>
                                                                {item.horseName.length > 14 ? `${item.horseName.substring(0, 14)}...` : item.horseName}
                                                            </span>
                                                            <span className='text-center' style={{ width: '4%' }}>|</span>
                                                            <span className='text-end' style={{ width: '45%' }}>
                                                                {moment(item.foalingDate).format(
                                                                    "MM/DD/YYYY"
                                                                )}
                                                            </span>
                                                        </li>
                                                    ))
                                                    }
                                                </>
                                            }
                                        </>
                                }
                            </ul>
                        </div>

                        {/* <div className='form-group mb-3 text-start'>
                            <label className='mb-2'>* Sire Name</label>

                            <input type="text" className='form-control'
                                name='sireName'
                                value={horsesEdit.sireName}
                                onChange={updateHorses}
                                placeholder='Sire Name' />
                        </div> */}
                        {errors?.sireName?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.sireName}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'  ref={lenRef}>
                        <div className='form-group text-start position-relative'>
                            <label className='mb-2'>* Dam Name </label>
                            <input
                                type="text"
                                className="form-control form-select"
                                value={horsesEdit.damName}
                                name="name"
                                onChange={getDamList}
                                onMouseDown={() => setDamShow(true)}
                                autocomplete="off"
                            />
                            <ul
                                className={`list-group position-absolute mt-1 w-100 shadow-lg bg-body rounded overflow-auto scrollbar ${damShow ? `` : `d-none`}`}
                                style={{ maxHeight: '200px' }}
                            >
                                {
                                    isDamLoad ? <li className="list-group-item text-center">
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Searching.....
                                    </li> : <> {
                                        damData == '' ? <>
                                            <li className="list-group-item text-center">Type 3 characters to search</li>
                                        </> : <>
                                            {
                                                damData.map((item, index) => (
                                                    <li
                                                        className="list-group-item d-flex justify-content-between"
                                                        style={{ cursor: 'pointer', fontSize: '14px' }}
                                                        onClick={() => addDamInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                                                    >
                                                        <span className='text-start' style={{ width: '53%' }}>
                                                            {item.horseName.length > 14 ? `${item.horseName.substring(0, 14)}...` : item.horseName}
                                                        </span>
                                                        <span className='text-center' style={{ width: '4%' }}>|</span>
                                                        <span className='text-end' style={{ width: '45%' }}>
                                                            {moment(item.foalingDate).format(
                                                                "MM/DD/YYYY"
                                                            )}
                                                        </span>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    }
                                    </>
                                }
                            </ul>
                        </div>
                        {/* <div className='form-group mb-3 text-start'>
                            <label className='mb-2'>* Dam Name </label>
                            <input type="text" className='form-control'
                                name='damName'
                                value={horsesEdit.damName}
                                onChange={updateHorses}
                                placeholder='Dam Name' />
                        </div> */}
                        {errors?.damName?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.damName}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 mt-2 text-start'>
                            <label className='mb-2'>* Hip Number</label>
                            <input type="text" className='form-control'
                                name='hipNumber'
                                value={horsesEdit.hipNumber}
                                onChange={updateHorses}
                                placeholder='Hip Number' />
                        </div>
                        {errors?.hipNumber?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.hipNumber}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 mt-2 text-start'>
                            <label className='mb-2'>Sex</label>
                            <select className='form-control'
                                name='sex'
                                value={horsesEdit.sex}
                                onChange={updateHorses}
                            >
                                <option>Select</option>
                                <option value={'male'} selected={horsesEdit.sex == 'male' ? true : false}>Male</option>
                                <option value={'female'} selected={horsesEdit.sex == 'female' ? true : false}>Female</option>
                            </select>
                        </div>
                        {errors?.sex?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.sex}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2'>* New Horse Name</label>
                            <input type="text" className='form-control'
                                name='name'
                                value={horsesEdit.name}
                                onChange={updateHorses}
                                placeholder='New Horse Name' />
                        </div>
                        {errors?.name?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.name}</p>
                        ) : null}
                    </div>

                    {/* <div className='col-md-6'>
                    <div className='form-group mb-3'>
                        <label className='mb-2'>* Date of Birth</label>
                        <input type="date" className='form-control' placeholder='Select Time' />
                    </div>
                </div> */}

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2'>Price</label>
                            <input type="number" className='form-control'
                                name='price'
                                value={horsesEdit.price}
                                onChange={updateHorses}
                                placeholder='Price' />
                        </div>
                        {errors?.price?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.price}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2'>Consignor </label>
                            <input type="text" className='form-control'
                                name='consignor'
                                value={horsesEdit.consignor}
                                onChange={updateHorses}
                                placeholder='Consignor' />
                        </div>
                        {errors?.consignor?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.consignor}</p>
                        ) : null}
                    </div>

                    <div className='modal__footer'>
                        <div className='col-md-12 d-flex justify-content-end'>
                            <button onClick={() => handleCloseAdd(false)} className='btn btn-defaut border site-s-font mx-3'>{horses.length == 1 ? `Cancel` : `Skip`}</button>
                            {loadingSave ? (<button className='btn btn-primary site-s-font' disabled>Saving... </button>) : (showSave(horses.length))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddData