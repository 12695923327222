import React from 'react'

const EventUpdateModal = ({show,onClose,eventName,setEventName, saveEvent, isGetLoading, onSubmitUpdate}) => {
if(!show) return

  return (
    <div className='modal__wrapper'>
        <div className='modal__card'>
            
            <div className='modal__head'>
                <h3>Update Event</h3>
                <span onClick={onClose}><i class="fas fa-times"></i></span>
            </div>

            <div className='row mt-3 modal__form'>
                <div className='col-md-12'>
                    <div className='form-group mb-3 text-start'>
                        <label className='mb-2 text-start'> <span>*</span> Event Name</label>
                        <input type="text" value={eventName.name} onChange={(e) => setEventName({...eventName, name: e.target.value})} className='form-control' placeholder='Event Name' />
                    </div>
                </div>

                <div className='modal__footer'>
                    <div className='col-md-12 d-flex justify-content-end'>
                        <button className='btn btn-defaut border site-s-font mx-3' onClick={onClose}>Cancel</button>
                        <button className='btn btn-primary site-s-font' disabled={isGetLoading} onClick={onSubmitUpdate}>Save {isGetLoading ? 'processing...' : ''} </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default EventUpdateModal