/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import { AiOutlineEdit } from "react-icons/ai";

import { baseUrl, API_URL } from "../config/config";
import { logout } from "../features/slices/authslice.";
import SortIcon from "./icons/SortIcon";

const ViewShareRow = (props) => {
  const {
    item,
    setShow,
    noteShow,
    setNoteShow,
    horses,
    setHorses,
    pageSize,
    currentPage,
    horsesEdit,
    setHorsesEdit,
    getEventDetails,
    handlePagination,
    breedStatus,
    setBreedStatus,
    getEventFavorite,
    getEventSearchPage,
  } = props;
  const [showBread, setShowBread] = useState(false);
  const [favoriteLoad, setFavoriteLoad] = useState(false);
  const [checkboxChek, setCheckboxChek] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const handleShow = (value) => {
    setShowBread(showBread === value ? 0 : value);
  };

  console.log("item-----", item);

  const handleNoteShow = (value) => {
    setHorsesEdit(item);
    setNoteShow(true);
  };

  // console.log('item.gsrGridRows', item.gsrGridRows);

  const [sort, setSort] = useState(true);
  const [gsrSort, setGsrSort] = useState("");
  const [gsrList, setGSRList] = useState(item.gsrGridRows);

  const getGsrDirt = (info) => {
    let newSort = sort == true ? false : true;
    setSort(newSort);
    setGsrSort(info);
    if (sort == true) {
      setGSRList(item.gsrGridRows.sort((a, b) => a.dirt - b.dirt));
    } else {
      setGSRList(item.gsrGridRows.sort((a, b) => b.dirt - a.dirt));
    }
  };
  const getGsrTurf = (info) => {
    let newSort = sort == true ? false : true;
    setSort(newSort);
    setGsrSort(info);
    if (sort == true) {
      setGSRList(item.gsrGridRows.sort((a, b) => a.turf - b.turf));
    } else {
      setGSRList(item.gsrGridRows.sort((a, b) => b.turf - a.turf));
    }
  };
  const getGsrSynth = (info) => {
    let newSort = sort == true ? false : true;
    setSort(newSort);
    setGsrSort(info);
    if (sort == true) {
      setGSRList(item.gsrGridRows.sort((a, b) => a.synthetic - b.synthetic));
    } else {
      setGSRList(item.gsrGridRows.sort((a, b) => b.synthetic - a.synthetic));
    }
  };
  const getGsrWet = (info) => {
    let newSort = sort == true ? false : true;
    setSort(newSort);
    setGsrSort(info);
    if (sort == true) {
      setGSRList(item.gsrGridRows.sort((a, b) => a.wet - b.wet));
    } else {
      setGSRList(item.gsrGridRows.sort((a, b) => b.wet - a.wet));
    }
  };

  const FavoriteHandler = async (id, isFavorite) => {
    setFavoriteLoad(true);
    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "PUT",
      body: JSON.stringify({
        IsFavorite: isFavorite,
      }),
      headers: myHeaders,
      redirect: "follow",
    };

    const create = await fetch(`${API_URL}/Horse/edit/${id}`, requestOptions);
    const result = await create.json();

    if (create.status === 401) {
      return logout();
    }

    console.log("result", result);

    if (result.isSuccess == true) {
      // Swal.fire({
      //     icon: 'success',
      //     title: 'Favorite Updated Successfully!',
      // }); 

      if (breedStatus == "search") {
        getEventSearchPage(currentPage, pageSize);
      } else {
        getEventFavorite(currentPage, pageSize);
      }

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Favorite Updating Failed!',
      });
    }
    setTimeout(() => {
      setFavoriteLoad(false);
    }, 1200);
  };

  return (
    <>
      <tr className="table__row table__row_content">
        <td className="px-2 py-3">
          {favoriteLoad ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden"></span>
            </div>
          ) : (
            <>
              {item.isFavorite ? (
                <i className="fas fa-star star-fill"></i>
              ) : (
                <i className="far fa-star"></i>
              )}
            </>
          )}
        </td>
        <td className="py-3">{item.id}</td>
        <td className="py-3">
          <div className="w-100">
            <div className="w-100 d-flex align-items-center">
              {item.sireName}
            </div>
            {item.isSireCpuSelected == null ? null : (
              <>
                {item.isSireCpuSelected == true ? (
                  <div className="w-100 d-flex align-items-center mt-2">
                    <img
                      src="/assets/img/icon/cpu_picked.svg"
                      style={{
                        width: "65.62px",
                        height: "16px",
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </td>
        <td className="py-3">
          <div className="w-100">
            <div className="w-100 d-flex align-items-center">
              {item.damName}
            </div>
            {item.isDamCpuSelected == null ? null : (
              <>
                {item.isDamCpuSelected == true ? (
                  <div className="w-100 d-flex align-items-center mt-2">
                    <img
                      src="/assets/img/icon/cpu_picked.svg"
                      style={{
                        width: "65.62px",
                        height: "16px",
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </td>
        <td className="py-3">{item.name}</td>
        <td className="py-3" style={{ textTransform: "capitalize" }}>
          {item?.sex?.length > 1 ? `${item?.sex?.substring(0, 1)}` : item?.sex}
        </td>
        <td className="py-3">{item.hipNumber}</td>
        <td>{item.gsrDirt}</td>
        <td>{item.gsrTurf}</td>
        <td>{item.gsrSynth}</td>
        <td>{item.gsrWet}</td>
        <td className="py-3">${item.price}</td>
        <td className="py-3">
          <div
            className="table__comment"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setHorsesEdit(item);
              handleNoteShow();
            }}
          >
            <img
              src="/assets/img/icon/chat.svg"
              style={{
                width: "21.68px",
                height: "21.68px",
              }}
            />
            <span className="comment__badge">{item.horseNotes.length}</span>
          </div>
        </td>
      </tr>
      {showBread ? (
        <>
          {item.gsrGridRows == "" ? (
            <tr className="bg-gray" style={{ height: "37px" }}>
              <td className="text-center" colSpan={13}>
                No Data Found
              </td>
            </tr>
          ) : (
            <>
              <tr
                className=""
                style={{ height: "37px", backgroundColor: `#E5E5E5` }}
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td colSpan={2}>
                  <span
                    className="d-flex align-items-center justify-content-start"
                    style={{
                      width: "100%",
                      height: "37px",
                      backgroundColor: "#FFF0",
                      marginLeft: "-7px",
                    }}
                  >
                    Distance/Furlongs{" "}
                    {/* <i class="fa_sort fa" style={{ marginLeft: "8px", color: '#BFBFBF !important' }}>
                                                &#xf0dc;
                                            </i> */}
                  </span>
                </td>
                <td>
                  <span
                    className="d-flex align-items-center justify-content-start"
                    style={{
                      width: "100%",
                      height: "37px",
                      backgroundColor: "#FFF0",
                      marginLeft: "-7px",
                      cursor: "pointer",
                    }}
                    onClick={() => getGsrDirt("Dirt")}
                  >
                    Dirt{" "}
                    {gsrSort == "Dirt" ? (
                      <SortIcon
                        select={gsrSort == "Dirt" ? true : false}
                        sort={sort}
                      />
                    ) : (
                      <SortIcon select={null} sort={sort} />
                    )}
                  </span>
                </td>
                <td>
                  <span
                    className="d-flex align-items-center justify-content-start"
                    style={{
                      width: "100%",
                      height: "37px",
                      backgroundColor: "#FFF0",
                      marginLeft: "-7px",
                      cursor: "pointer",
                    }}
                    onClick={() => getGsrTurf("Turf")}
                  >
                    Turf{" "}
                    {gsrSort == "Turf" ? (
                      <SortIcon
                        select={gsrSort == "Turf" ? true : false}
                        sort={sort}
                      />
                    ) : (
                      <SortIcon select={null} sort={sort} />
                    )}
                  </span>
                </td>
                <td>
                  <span
                    className="d-flex align-items-center justify-content-start"
                    style={{
                      width: "100%",
                      height: "37px",
                      backgroundColor: "#FFF0",
                      marginLeft: "-7px",
                      cursor: "pointer",
                    }}
                    onClick={() => getGsrSynth("Synth")}
                  >
                    Synth{" "}
                    {gsrSort == "Synth" ? (
                      <SortIcon
                        select={gsrSort == "Synth" ? true : false}
                        sort={sort}
                      />
                    ) : (
                      <SortIcon select={null} sort={sort} />
                    )}
                  </span>
                </td>
                <td>
                  <span
                    className="d-flex align-items-center justify-content-start"
                    style={{
                      width: "100%",
                      height: "37px",
                      backgroundColor: "#FFF0",
                      marginLeft: "-7px",
                      cursor: "pointer",
                    }}
                    onClick={() => getGsrWet("Wet")}
                  >
                    Wet{" "}
                    {gsrSort == "Wet" ? (
                      <SortIcon
                        select={gsrSort == "Wet" ? true : false}
                        sort={sort}
                      />
                    ) : (
                      <SortIcon select={null} sort={sort} />
                    )}
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>
              {item.gsrGridRows.map((item, index) => (
                <>
                  {item.distanceDisplay == "ALL" ? null : (
                    <tr
                      className=""
                      style={{
                        height: "37px",
                        backgroundColor: `${index % 2 == 0 ? `#EFEFEF` : `#E5E5E5`
                          }`,
                      }}
                    >

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td colSpan={2}>{item.distanceDisplay}</td>
                      <td>{item.dirt}</td>
                      <td>{item.turf}</td>
                      <td>{item.synthetic}</td>
                      <td>{item.wet}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </>
              ))}
            </>
          )}
        </>
      ) : null}

    </>
  );
};

export default ViewShareRow;
