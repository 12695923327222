import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Login from '../components/Login'
import { Navigate } from 'react-router-dom'
import Loading from '../components/Loading'

const PrivateRoute = () => {
    const [globalLoading, setGlobalLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setGlobalLoading(false)
        },700)
    },[])
    const token = useSelector(state => state.auth.token)

    if(globalLoading){
        return <Loading />
    }
    return token ? <Outlet /> : <Navigate to={'/login'} />
}

export default PrivateRoute;