/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Nav from "./components/Nav";
import SalseAssistant from "./components/SalseAssistant";
import Events from "./components/Events";
import BreedHorses from "./components/BreedHorses";
import BreedHistory from "./components/BreedHistory";
import AddToEvent from "./components/AddToEvent";
import FavouriteHorses from "./components/FavouriteHorses";
import EventDetails from "./components/EventDetails";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { Redirect } from "./components/Redirect";
import { useDispatch } from "react-redux";
import { processToken } from "./features/slices/authslice.";
import PrivateRoute from "./utils/PrivateRoute";
import 'react-toastify/dist/ReactToastify.css';
import EventBreed from "./components/EventBreed";
import EventShare from "./components/EventShare";


function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(processToken())
  }, [])
  return (
    <>
      <Routes>
        {/* Public Routees  */}
        <Route path="/" element={<Redirect />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/share/:id" element={<EventShare />} />


        {/* Protected Routes  */}
        <Route element={<PrivateRoute />}>
          <Route path="/breed-horses" element={<SalseAssistant />} />
          <Route path="/events" element={<Events />} />
          <Route path="/breed-history" element={<BreedHistory />} />
          <Route path="/bred-horses" element={<BreedHorses />} />
          <Route path="/add-to-event" element={<AddToEvent />} />
          <Route path="/event/:id" element={<EventDetails />} />
          <Route path="/favourites" element={<FavouriteHorses />} />
          <Route path="/event-breed" element={<EventBreed />} />
          <Route path="/share/:id" element={<EventShare />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
